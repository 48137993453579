import React from "react";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { grey400 } from "material-ui/styles/colors";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import forOwn from "lodash/forOwn";
import isNumber from "lodash/isNumber";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";

import PageBase from "../components/PageBase";
import {
  webServiceRoutes,
  UIRoutes,
  allowedKeys,
  errorFieldMapping,
  distributorList,
  phaseList,
  groupVoltageList
} from "../config/constants";
import { VALIDATE_EMAIL, formatPhone } from "../util/methods";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";

const styles = {
  toggleDiv: {
    maxWidth: 300,
    marginTop: 40,
    marginBottom: 5
  },
  toggleLabel: {
    color: grey400,
    fontWeight: 100
  },
  buttons: {
    marginTop: 30,
    float: "right"
  },
  saveButton: {
    marginLeft: 5
  },
  googlePlacesInput: {
    padding: 0,
    position: "relative",
    border: "none",
    outline: "none",
    backgroundColor: "rgba(0, 0, 0, 0)",
    cursor: "inherit",
    font: "inherit",
    opacity: 1,
    height: 55,
    boxSizing: "border-box",
    width: "100%",
    marginRight: 10
  },
  googlePlacesWrapper: {
    fontSize: 16,
    width: "48%",
    height: 47,
    float: "left",
    position: "relative",
    backgroundColor: "transparent",
    fontFamily: "Roboto, sans-serif",
    transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    cursor: "auto",
    borderBottom: "1px solid rgb(224, 224, 224)",
    zIndex: 100,
    marginRight: 10,
    bottom: 20
  },
  inputTextField: {
    height: 55,
    width: "48%",
    marginRight: 10
  },
  cnpjTextField: {
    marginRight: 0,
    marginLeft: 10,
    height: 55,
    width: "48%"
  },
  distributorTextField: {
    zIndex: 1,
    height: 55,
    width: "48%",
    marginRight: 10
  },
  errorTextStyle: {
    float: "left"
  }
};

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        client: "",
        cnpj: "",
        unit_number: "",
        address: "",
        distributor: "",
        latitude: "",
        longitude: "",
        phase: "",
        power_plant: "",
        group_voltage: "",
        zipCode: "",
        country: "",
        state: "",
        contract: "",
        nickName: ""
      },
      isLoading: false,
      error: {}
    };
  }

  /**
   * validate add user form
   */
  validateForm() {
    set(this.state, "error", {});
    const { form, error } = this.state;
    forOwn(form, (value, key) => {
      if (
        typeof value == "string" &&
        isEmpty(value) &&
        key !== "power_plant" &&
        key !== "nickName" &&
        key !== "contract" &&
        key !== "zipCode" &&
        key !== "state" &&
        key !== "country"
      ) {
        set(error, key, `${errorFieldMapping[key]} is required`);
        // set(unitFormError, key, `${key} is required`);
      }
      if (key === "contract" && this.state.form.power_plant && !value) {
        set(error, `contract`, "Contract is required");
      }

      // if (typeof value == "string" && isEmpty(value)) {
      //   set(error, key, `${errorFieldMapping[key]} is required`);
      // }
      if (key === "email") {
        const isValidEmail = VALIDATE_EMAIL(value);
        if (!isValidEmail) {
          set(this.state, `error.${key}`, "Invalid email");
        }
      }

      if (key == "telephone") {
        let telephone = value.replace(/\D/g, "");
        if (telephone.length < 11) {
          set(this.state, `error.telephone`, "Telphone not valid");
        }
      }

      if (
        (key === "latitude" && !isNumber(value)) ||
        (key === "longitude" && !isNumber(value))
      ) {
        set(this.state, `error.address`, "Please select address from the list");
      }
    });
    this.setState({ error });
    if (Object.keys(error).length) {
      return false;
    }
    return true;
  }

  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, `form.${field}`, value);
    set(this.state, `error.${field}`, null);
    if (field === "email") {
      const isValidEmail = VALIDATE_EMAIL(value);
      if (!isValidEmail) {
        set(this.state, `error.${field}`, "Invalid email");
      }
    }
    if (field == "telephone") {
      let telephone = value.replace(/\D/g, "");
      if (telephone.length < 11) {
        set(this.state, `error.telephone`, "Telphone not valid");
      }
    }
    this.setState(this.state);
  };

  /**
   * on click save button
   */
  onSaveButtonClick = () => {
    if (!this.validateForm()) {
      return;
    }
    const { form } = this.state;
    form.contract = form.contract ? form.contract.toString() : 0;
    form.telephone = form.telephone.replace(/\D/g, "");
    set(form, "status", "approved");
    let options = {
      method: "POST",
      url: webServiceRoutes.ADD_USER,
      data: form
    };
    this.setState({ isLoading: true });

    /**
     * server call for add user
     */
    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data) {
          showSnackBar("Data saved successfully");
          this.setState({ isLoading: false });

          this.props.history.push(UIRoutes.DASHBOARD);
        }
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * on changing value of address input fields
   * @param {string} address
   */
  handleChange = address => {
    set(this.state, "form.address", address);
    set(this.state, `error.address`, null);
    this.setState(this.state);
  };

  /**
   * on selecting address
   * @param {object} address
   */
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const details = results.length ? results[0] : [];
        set(
          this.state,
          "form.zipCode",
          get(
            find(
              details.address_components,
              address => address.types.indexOf("postal_code") > -1
            ),
            "long_name",
            null
          )
        );
        set(
          this.state,
          "form.country",
          get(
            find(
              details.address_components,
              address => address.types.indexOf("country") > -1
            ),
            "long_name",
            null
          )
        );
        set(
          this.state,
          "form.state",
          get(
            find(
              details.address_components,
              address =>
                address.types.indexOf("administrative_area_level_1") > -1
            ),
            "long_name",
            null
          )
        );
        return getLatLng(results[0]);
      })
      .then(latLng => {
        set(this.state, "form.address", address);
        set(this.state, `form.latitude`, latLng.lat);
        set(this.state, `form.longitude`, latLng.lng);
        this.setState(this.state);
      })
      .catch(error => console.error("Error", error));
  };

  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  handleSelectFieldChange = (field, value) => {
    set(this.state, `form.${field}`, value);
    set(this.state, `error.${field}`, null);
    this.setState(this.state);
  };

  /**
   * render Add user page
   */
  render() {
    const { form, error, isLoading } = this.state;
    return (
      <PageBase title="Add user" navigation="Add user">
        {isLoading ? <Loader /> : null}
        <form>
          <TextField
            hintText="First Name"
            value={get(form, "first_name", "")}
            errorText={get(error, "first_name", "")}
            onChange={e => {
              this.onChange("first_name", e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
          />
          <TextField
            hintText="Last Name"
            value={get(form, "last_name", "")}
            errorText={get(error, "last_name", "")}
            onChange={e => {
              this.onChange("last_name", e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
          />
          <TextField
            hintText="Email"
            type="email"
            value={get(form, "email", "")}
            errorText={get(error, "email", "")}
            onChange={e => {
              this.onChange("email", e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
          />
          <TextField
            hintText="Telephone"
            value={formatPhone(get(form, "telephone", ""))}
            errorText={get(error, "telephone", "")}
            onChange={e => {
              this.onChange("telephone", e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            onKeyDown={e => {
              let numbers = e.target.value.replace(/\D/g, "");
              if (
                allowedKeys.indexOf(e.which) === -1 ||
                (numbers.length === 11 &&
                  [8, 9, 37, 39, 46].indexOf(e.which) === -1)
              ) {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            }}
          />
          <TextField
            hintText="Client"
            value={get(form, "client", "")}
            errorText={get(error, "client", "")}
            onChange={e => {
              this.onChange("client", e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
          />
          <TextField
            hintText="Unit"
            value={get(form, "unit_number", "")}
            errorText={get(error, "unit_number", "")}
            onChange={e => {
              this.onChange("unit_number", e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            onKeyDown={e => {
              if (allowedKeys.indexOf(e.which) === -1) {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            }}
          />
          <SelectField
            value={get(form, "phase", "")}
            style={{ bottom: 17, marginRight: 10, width: "48%" }}
            onChange={(e, index, value) => {
              this.handleSelectFieldChange("phase", value);
            }}
            floatingLabelText="Phase"
            errorText={get(error, "phase", "")}
          >
            {phaseList.map(elem => {
              return <MenuItem key={elem} value={elem} primaryText={elem} />;
            })}
          </SelectField>
          <SelectField
            value={get(form, "group_voltage", "")}
            style={{ bottom: 17, width: "48%" }}
            onChange={(e, index, value) => {
              this.handleSelectFieldChange("group_voltage", value);
            }}
            floatingLabelText="Group Voltage"
            errorText={get(error, "group_voltage", "")}
          >
            {groupVoltageList.map(elem => {
              return <MenuItem key={elem} value={elem} primaryText={elem} />;
            })}
          </SelectField>
          <TextField
            hintText="Nick name"
            value={get(form, "nickName", "")}
            // errorText={get(unitFormError, "nickName", "")}
            // errorStyle={{
            //   float: "left"
            // }}
            onChange={e => {
              this.onChange("nickName", e.target.value);
            }}
            style={{ ...styles.inputTextField, bottom: 20 }}
          />
          <TextField
            hintText="Contract - R$/MWh"
            type="number"
            value={get(form, "contract", "")}
            errorText={get(error, "contract", "")}
            errorStyle={{
              float: "left"
            }}
            onChange={e => {
              this.onChange("contract", e.target.value);
            }}
            style={{ ...styles.inputTextField, bottom: 20 }}
          />

          <TextField
            hintText="CNPJ/CPF"
            value={get(form, "cnpj", "")}
            errorText={get(error, "cnpj", "")}
            onChange={e => {
              this.onChange("cnpj", e.target.value);
            }}
            style={{ ...styles.inputTextField, bottom: 20 }}
            errorStyle={styles.errorTextStyle}
            onKeyDown={e => {
              if (allowedKeys.indexOf(e.which) === -1) {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            }}
          />
          <TextField
            hintText="Power plant"
            value={get(form, "power_plant", "")}
            errorText={get(error, "power_plant", "")}
            onChange={e => {
              this.onChange("power_plant", e.target.value);
            }}
            style={{ ...styles.inputTextField, bottom: 20 }}
            errorStyle={styles.errorTextStyle}
          />
          <PlacesAutocomplete
            value={get(this.state, "form.address", "")}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div style={styles.googlePlacesWrapper}>
                <input
                  {...getInputProps({
                    placeholder: "Address",
                    className: "location-search-input"
                  })}
                  style={styles.googlePlacesInput}
                />
                <div className="autocomplete-dropdown-container">
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="error-text">{get(error, "address", "")}</div>
              </div>
            )}
          </PlacesAutocomplete>
          <SelectField
            value={get(form, "distributor", "")}
            style={{ bottom: 35, width: "48%" }}
            onChange={(e, index, value) => {
              this.handleSelectFieldChange("distributor", value);
            }}
            floatingLabelText="Distributor"
            errorText={get(error, "distributor", "")}
          >
            {distributorList.map(elem => {
              return <MenuItem key={elem} value={elem} primaryText={elem} />;
            })}
          </SelectField>
          {/* <TextField
            hintText="Distributor"
            value={get(form, "distributor", "")}
            errorText={get(error, "distributor", "")}
            onChange={e => {
              this.onChange("distributor", e.target.value);
            }}
            style={styles.distributorTextField}
            errorStyle={styles.errorTextStyle}
          /> */}
          <div style={styles.buttons}>
            <Link to={UIRoutes.DASHBOARD}>
              <RaisedButton label="Cancel" />
            </Link>

            <RaisedButton
              label="Save"
              style={styles.saveButton}
              primary={true}
              onClick={this.onSaveButtonClick}
            />
          </div>
        </form>
      </PageBase>
    );
  }
}

export default AddUser;
