import React from "react";
import Assessment from "material-ui/svg-icons/action/assessment";
import Pending from "material-ui/svg-icons/action/update";
import Rejected from "material-ui/svg-icons/content/block";
import VerifiedUser from "material-ui/svg-icons/action/verified-user";
import DnsIcon from "@material-ui/icons/Dns";
import GroupIcon from "@material-ui/icons/Group";
import PieChartIcon from "@material-ui/icons/PieChart";
import OrderIcon from "material-ui/svg-icons/maps/local-mall";
const data = {
  menus: [
    { text: "DashBoard", icon: <Assessment />, link: "/" },
    { text: "Pending users", icon: <Pending />, link: "/pendingUsers" },
    { text: "Approved users", icon: <VerifiedUser />, link: "/approvedUsers" },
    { text: "Rejected users", icon: <Rejected />, link: "/rejectedUsers" },
    { text: "Categories", icon: <PieChartIcon style={{color:"grey"}}/>, link: "/categories" },
    { text: "Products", icon: <DnsIcon style={{color:"grey"}}ted />, link: "/products" },
    { text: "Distributors", icon: <GroupIcon style={{color:"grey"}} />, link: "/distributors" },
    { text: "Orders", icon: <OrderIcon style={{color:"grey"}} />, link: "/orders" }
  ]
};

export default data;
