import React, { useState, useEffect } from "react";
import {
  Table as MaterialTable,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

import Delete from "material-ui/svg-icons/action/delete";
import InfoIcon from "@material-ui/icons/Info";
import Edit from "material-ui/svg-icons/image/edit";
import SearchBar from "material-ui-search-bar";
import get from "lodash/get";
import Pagination from "./Pagination";
import { Loader } from "./Loader";
import { UIRoutes, webServiceRoutes } from "../config/constants";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import Toggle from "material-ui/Toggle/Toggle";
 import Product from "./Product";

const Table = (props) => {
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState([5, 10, 15]);
  const [numberOfRows, setNumberOfRows] = useState(5);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const styles = {
    searchBarWrapper: {
      top: 53,
      position: "absolute",
      right: 30,
    },
    searchBar: {
      margin: "0 auto",
      maxWidth: 500,
      float: "right",
      boxShadow: "none",
      border: "1px solid rgb(224, 224, 224)",
    },
    columns: {
      width_80: {
        width: 80,
      },
    },
  };

  useEffect(() => {
    setPage(1);
    setNumberOfRows(5);
    getData();
  }, [reload]);

  const getData = (numberOfRows, page) => {
    setIsLoading(true);
    const params = {
      url: props.data_url,
      params: {
        ...props.filters,
        page: page || 1,
        per_page: numberOfRows || 5,
        q: searchText || "",
      },
      method: "GET",
    };
    WebServiceRequest.callWebService(params)
      .then(async (result) => {
        await setData(get(result, "data.list_data", []));
        setTotal(get(result, "data.count", 0));
        setIsLoading(false);
      })
      .catch((e) => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        setIsLoading(false);
      });
  };

  const onPaginationFilterChange = (updatedState) => {
    const { numberOfRows, page } = updatedState;
    getData(numberOfRows, page);
    setNumberOfRows(numberOfRows);
    setPage(page);
  };

  const onAvailableButtonClick = (item) => {
    //  props.Available({state: { if(item==true) },});
    
  };

  const onEditButtonClick = (item) => {
    props.edit(item);
  };

  const onToggle = async (item,value) => {
    setIsLoading(true);
    await props.toggle(item,value);
    setIsLoading(false);
  };
  const onInfoButtonClick = (item) => {
    props.info(item);
  };

  const onDeleteButtonClick = async (item) => {
    setIsLoading(true);
    await props.delete(item);
    setReload(!reload);
  };

  const isEdgeBrowser = navigator.appVersion.indexOf("Edge") < 0;

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <div style={styles.searchBarWrapper}>
        <SearchBar
          onChange={(e) => setSearchText(e)}
          onRequestSearch={getData}
          style={styles.searchBar}
        />
      </div>
      <MaterialTable
        selectable={false}
        displayRowCheckbox={false}
        style={{ width: "100%" }}
        bodyStyle={
          isEdgeBrowser
            ? { overflow: "unset" }
            : { overflowX: "unset", overflowY: "unset" }
        }
      >
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            {props.columns.map(({ columnAlias }, i) => (
              <TableHeaderColumn key={i} style={styles.columns.width_80} key={i}>
                {columnAlias}
              </TableHeaderColumn>
            ))}
            {props.actions ? (
              <>
               {props.toggleAction?( <TableHeaderColumn style={styles.columns.width_80}>
                  {props.available?"IsAvailable":"IsAcitve"}
                </TableHeaderColumn>):(null)}
               
                <TableHeaderColumn style={styles.columns.width_80}>
                  Edit
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Delete
                </TableHeaderColumn>
               
              </>
            ) : (
              <>
              
                <TableHeaderColumn style={styles.columns.width_80}>
                  Info
                </TableHeaderColumn>
              </>
            )}
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {data.map((row, i) => (
            <TableRow key={i}>
              {props.columns.map(({ cell }, i) => (
                <TableRowColumn key={i} style={styles.columns.width_80}>
                  {cell(row)}
                </TableRowColumn>
              ))}
              {props.actions ? (
                <>
                {props.toggleAction?( <TableRowColumn style={styles.columns.width_80}>
                    <span
                      style={{ cursor: "pointer" }}
                    >
                      <Toggle  
                      defaultToggled={props.available?row.isAvailable:row.isActive}
                      onToggle={(e, newValue) => {
                          onToggle(JSON.parse(JSON.stringify(row)), newValue);
                      }}/>
                    </span>
                  </TableRowColumn>):(null)}
                  <TableRowColumn style={styles.columns.width_80}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        onEditButtonClick(JSON.parse(JSON.stringify(row)));
                      }}
                    >
                      <Edit />
                    </span>
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        onDeleteButtonClick(JSON.parse(JSON.stringify(row)));
                      }}
                    >
                      <Delete />
                    </span>
                  </TableRowColumn>
                 
                </>
              ) : (
                <>
                
                <TableRowColumn style={styles.columns.width_80}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      onInfoButtonClick(JSON.parse(JSON.stringify(row)));
                    }}
                  >
                    <InfoIcon />
                  </span>
                </TableRowColumn>
              </>)}
            </TableRow>
          
          ))}
        </TableBody>
      </MaterialTable>
      <Pagination
        total={total}
        rowsPerPage={rowsPerPage}
        page={page}
        numberOfRows={numberOfRows}
        updateRows={onPaginationFilterChange}
      />
    </div>
  );
};

export default Table;
