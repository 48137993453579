import React from "react";
import { Snackbar } from "material-ui";
import { Events } from "./events";

const SnackbarTime = 3000;

export const showSnackBar = message => {
  Events.trigger("showSnackBar", { message });
};

export default class NotificationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: false,
      snackBarMessage: ""
    };
  }

  componentDidMount() {
    let { id = null } = this.props;
    Events.on("showSnackBar", id ? id : "123456789", this.showSnackbar);
  }

  componentWillUnmount() {
    let { id = null } = this.props;
    Events.remove("showSnackBar", id ? id : "123456789");
  }

  showSnackbar = ({ message }) => {
    console.log("message from showSnackBar:- ", message);
    if (message) {
      this.setState({ snackBarMessage: message, openSnackBar: true }, () => {
        setTimeout(() => {
          this.setState({ snackBarMessage: "", openSnackBar: false });
        }, SnackbarTime + 500);
      });
    }
  };

  render() {
    const { openSnackBar = false, snackBarMessage = "" } = this.state;
    return (
      <Snackbar
        open={openSnackBar}
        message={snackBarMessage}
        contentStyle={{ textAlign: "center" }}
        autoHideDuration={SnackbarTime}
      />
    );
  }
}
