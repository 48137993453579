/* eslint-disable import/default */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import "./styles.scss";
import "font-awesome/css/font-awesome.css";
import "flexboxgrid/css/flexboxgrid.css";
render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  document.getElementById("root")
);
