import React from "react";
import get from "lodash/get";
import { WebServiceRequest } from "../util/webRequest";
import {  webServiceRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import Table from "./Table";
import { Link } from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { pink500 } from "material-ui/styles/colors";
import PageBase from "./PageBase";

export default function Product(props) {
  const filters = {};
  const columns = [
    {
      columnAlias: "Category",
      cell: (row) => {
        return row.app_category.title;
      },
    },
    {
      columnAlias: "Name",
      cell: (row) => {
        return row.name;
      },
    },
    {
      columnAlias: "Distributor",
      cell: (row) => {
        return row.app_distributor.label;
      },
    },
    {
      columnAlias: "Address",
      cell: (row) => {
        return row.address;
      },
    },
    {
      columnAlias: "Discount",
      cell: (row) => {
        return row.discount;
      },
    },
   
  ];

  const data_url = webServiceRoutes.PRODUCT_LIST;

  const AvailableForm = async (item) => {
    let availableOptions = {
      method: "AVAILABLE",
      url: webServiceRoutes.AVAILABLE_PRODUCT + "/" + item.productId,
      data: item,
    };

    try {
      const result = await WebServiceRequest.callWebService(availableOptions);
      let successMessage = get(result, "data.message");
      successMessage && showSnackBar(successMessage);
    } catch (e) {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
    }

  };

  const EditForm = (item) => {
    props.history.push({
      pathname: "/editProduct",
      state: { ...item, edit: true, },
    });

  };

  const DeleteProduct = async (item) => {
    let options = {
      method: "DELETE",
      url: webServiceRoutes.DELELTE_PRODUCT + "/" + item.productId,
      data: item,
    };

    try {
      const result = await WebServiceRequest.callWebService(options);
      let successMessage = get(result, "data.message");
      successMessage && showSnackBar(successMessage);
    } catch (e) {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
    }
  };
  const isAvailable = async (item,value) => {
    item.isAvailable=value
    const params = {
      url: webServiceRoutes.ISAVAILABLE_PRODUCT + item.productId,
      method: "put",
      data: item,
    };

    try {
      const result = await WebServiceRequest.callWebService(params);
      let successMessage = get(result, "data.message");
      successMessage && showSnackBar(successMessage);
    } catch (e) {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
    }
  };
  const styles = {
    floatingActionButton: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <PageBase title="Products" navigation="Products">
        <div>
          <Link to="/addProduct">
            <FloatingActionButton
              style={styles.floatingActionButton}
              backgroundColor={pink500}
            >
              <ContentAdd />
            </FloatingActionButton>
          </Link>
          <Table
            actions={true}
            toggleAction={true}
            toggle={isAvailable}
            available={true}
            edit={EditForm}
            delete={DeleteProduct}
            data_url={data_url}
            filters={filters}
            columns={columns}
          />
        </div>
      </PageBase>
    </div>
  );
}
