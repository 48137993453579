let Callbacks = {};

export const Events = {
  on: (at, id, callback) => {
    return Events.listen(at, id, callback);
  },
  listen: (at, id, callback) => {
    if (at === "") {
      return false;
    }
    if (at in Callbacks) {
      Callbacks[at][id] = callback;
    } else {
      Callbacks[at] = {};
      Callbacks[at][id] = callback;
    }
    return id;
  },
  t: (at, data) => {
    return Events.trigger(at, data);
  },
  trigger: (at, data) => {
    data = data || "";
    let obj = Callbacks[at];
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        obj[prop](data);
      }
    }
  },
  rm: (at, id) => {
    return Events.remove(at, id);
  },
  remove: (at, id) => {
    delete Callbacks[at][id];
  },
  removeAll: at => {
    delete Callbacks[at];
  }
};
