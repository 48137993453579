import React, { useState, useEffect } from "react";
import PageBase from "../components/PageBase";
import TextField from "material-ui/TextField";
import TextFieldNew from "@material-ui/core/TextField";
import PlacesAutocomplete from "react-places-autocomplete";
import AutoComplete from "material-ui/AutoComplete";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
import { webServiceRoutes, UIRoutes } from "../config/constants";
import { get, map, debounce, add } from "lodash";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

export default function OrderInformation(props) {
  const [categoryId, setCategoryId] = useState("");
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [userUnits, setUserUnits] = useState([]);
  const [unitNumbers, setUnitNumbers] = useState([]);
  const [name, setName] = useState("");
  const [product_distributor, setProduct_distributor] = useState("");
  const [unique_code, setUnique_code] = useState("");
  const [location, setLocation] = useState("");
  const [discount, setDiscount] = useState("");
  const [minimum_months, setMinimum_months] = useState("");
  const [product_address, setProduct_address] = useState("");
  const [consumption_kwh, setConsumption_kwh] = useState("");
  const [operation_start_month, setOperation_start_month] = useState("");
  const [infoUnits_Id, setInfoUnits_Id] = useState("");
  const [contract_status, setContract_status] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [monthlyEquivalent, setMonthlyEquivalent] = useState(0);
  const [availedDiscount, setAvailedDiscount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userStatus, setUserStatus] = useState("verified");
  const [emails, setEmails] = useState([]);
  const [products, setProducts] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [referenceRate, setReferenceRate] = useState("");
  const [fullRate, setFullRate] = useState("");
  const [groupVoltage, setGroupVoltage] = useState("");
  const [maximum_KWH, setMaximum_KWH] = useState("");
  const [minimum_KWH, setMinimum_KWH] = useState("");
  const [totalAvailable_KWH, setTotalAvailable_KWH] = useState("");
  const [nickName, setNickName] = useState("");
  const [phase, setPhase] = useState("");
  const [unitAddress, setUnitAddress] = useState("");
  const [contract, setContract] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [isDeleted, setIsDeleted] = useState("");
  const [unit_group_voltage, setUnit_group_voltage] = useState("");
  const [unit_power_plant, setUnit_power_plant] = useState("");
  const [unitNum, setUnitNum] = useState("");
  const [perceived_discount, setPerceived_discount] = useState(0);
  const [productCategory, setProductCategory] = useState("");
  const [productName, setProductName] = useState("");
  const [contract_status_list, setContract_status_list] = useState([
    "Verified",
    "Pending",
    "Canceled",
    "Expired",
  ]);
  const [unit_distributor, setUnit_distributor] = useState("");
  const [distributorMismatch, setDistributorMismatch] = useState(false);
  const [consumption_error, setConsumption_error] = useState(false);
  const [product_id, setProduct_id] = useState("");
  const [distributorLabel, setDistributorLabel] = useState("");

  useEffect(
    () => {
      fullRate &&
        discount &&
        referenceRate &&
        setPerceived_discount((fullRate * (discount / 100)) / referenceRate);
    },
    [fullRate, discount, referenceRate]
  );

  useEffect(
    () => {
      if (!consumption_kwh) setMonthlyEquivalent(0);
      else {
        setMonthlyEquivalent(
          (
            consumption_kwh *
            (referenceRate * (1 - perceived_discount) +
              (fullRate - referenceRate))
          ).toFixed(2)
        );

        consumption_kwh && consumption_kwh > totalAvailable_KWH
          ? setConsumption_error(true)
          : setConsumption_error(false);
      }
    },
    [consumption_kwh, perceived_discount]
  );

  useEffect(
    () => {
      productName &&
      product_distributor.toUpperCase() != unit_distributor.toUpperCase()
        ? setDistributorMismatch(true)
        : setDistributorMismatch(false);
    },
    [product_distributor, unit_distributor]
  );

  useEffect(
    () => {
   if(!info){   
      setInfoUnits_Id("");
      setNickName("");
      setIsDeleted("");
      setUnit_group_voltage("");
      setUnit_power_plant("");
      setPhase("");
      setUnitAddress("");
      setContract("");
      setUnitNum("");
      setCnpj("");
      setUnit_distributor("");
    }
    },
    [userEmail]
  );

  useEffect(
    () => {
      if (!monthlyEquivalent) setAvailedDiscount(0);
      else {
        setAvailedDiscount(
          (fullRate * consumption_kwh - monthlyEquivalent).toFixed(2)
        );
      }
    },
    [monthlyEquivalent]
  );

  useEffect(() => {
    if (props.location.state) {
      let p = props.location.state;
      setInfo(true);
      setUserId(p.user_id);
      setUserEmail(p.user_email);
      setTelephone(p.user_telephone);
      setFirstName(p.first_name);
      setLastName(p.last_name);
      setUserAddress(p.user_address);

      setUnitNum(p.unit_number);
      setInfoUnits_Id(p.infoUnits_Id);
      setNickName(p.infoUnit_nickName);
      setIsDeleted(p.isDeleted);
      setUnit_group_voltage(p.infoUnit_group_voltage);
      setUnit_power_plant(p.power_plant);
      setPhase(p.phase);
      setUnitAddress(p.infoUnit_address);
      setContract(p.infoUnit_contract);
      setCnpj(p.cnpj);
      setUnit_distributor(p.distributor);

      setCategoryId(p.app_category.categoryId);
      setProductCategory(p.app_category.title);
      setProduct_id(p.app_product.productId);
      setUnique_code(p.app_product.unique_code);
      setProduct_address(p.app_product.address);
      setProductName(p.app_product.name);
      setDiscount(p.app_product.discount);
      setProduct_distributor(p.app_product.distributor);
      setDistributorLabel(p.distributor_label);
      setTotalAvailable_KWH(p.app_product.total_available_kwh);
      setMinimum_months(p.app_product.minimum_months);
      setOperation_start_month(p.app_product.operation_start_month);
      setMinimum_KWH(p.app_product.minimum_amount_kwh);
      setMaximum_KWH(p.app_product.maximum_amount_kwh);
      setGroupVoltage(p.app_product.group_voltage);
      setFullRate(p.app_product.full_rate);
      setReferenceRate(p.app_product.reference_rate);

      setConsumption_kwh(p.consumption_kwh);
      setContract_status(p.contract_status);
    } else {
      setIsLoading(true);
      const params = {
        url: webServiceRoutes.ACTIVE_CATEGORY_LIST,
        method: "GET",
      };
      WebServiceRequest.callWebService(params)
        .then(async (result) => {
          await setCategories(get(result, "data.list_data", []));
          await setCategoryList(
            map(get(result, "data.list_data", []), "title")
          );
          setIsLoading(false);
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          setIsLoading(false);
        });
    }
  }, []);

  let formData = {
    user_id: userId,
    first_name: firstName,
    last_name: lastName,
    user_telephone: telephone,
    user_email: userEmail,
    user_address: userAddress,
    productId: product_id,
    product_unique_code: unique_code,
    name: productName,
    distributor: product_distributor,
    product_address,
    product_discount: discount,
    total_available_kwh: totalAvailable_KWH,
    minimum_months,
    operation_start_month,
    minimum_amount_kwh: minimum_KWH,
    maximum_amount_kwh: maximum_KWH,
    product_group_voltage: groupVoltage,
    full_rate: fullRate,
    reference_rate: referenceRate,
    infoUnits_Id,
    isDeleted,
    infoUnit_group_voltage: unit_group_voltage,
    power_plant: unit_power_plant,
    infoUnit_nickName: nickName,
    phase,
    infoUnit_address: unitAddress,
    infoUnit_contract: contract,
    cnpj,
    unit_number: unitNum,
    categoryId,
    consumption_kwh,
    availed_discount: availedDiscount,
    perceived_discount: perceived_discount.toFixed(4),
    monthly_equivalent: monthlyEquivalent,
    contract_status,
    distributor_label: distributorLabel,
  };

  const handleChange = (e, field) => {
    let options = {};
    if (field === "email") {
      options = {
        url: webServiceRoutes.APPROVED_USER_LIST,
        params: {
          q: e || "",
        },
        method: "GET",
      };
    } else if (field === "product") {
      setIsLoading(true);

      options = {
        url: webServiceRoutes.CATEGORY_PRODUCT_LIST,
        params: {
          categoryId: e || "",
        },
        method: "GET",
      };
    } else if (field === "userUnits") {
      setIsLoading(true);

      options = {
        url: "/userUnit/" + e,
        method: "GET",
      };
    }
    WebServiceRequest.callWebService(options)
      .then(async (result) => {
        if (field === "email") {
          await setUsers(get(result, "data.list_data", []));
          setEmails(map(get(result, "data.list_data", []), "email"));
        } else if (field === "product") {
          await setProducts(get(result, "data.list_data", []));
          setProductNames(map(get(result, "data.list_data", []), "name"));
          setIsLoading(false);
        } else if (field === "userUnits") {
          await setUserUnits(get(result, "data", []));
          setUnitNumbers(map(get(result, "data", []), "unit_number"));
          setIsLoading(false);
        }
      })
      .catch((e) => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (info) {
      let options = {
        method: "PUT",
        url: webServiceRoutes.UPDATE_ORDER + props.location.state.id,
        data: formData,
      };
      setIsLoading(true);
      WebServiceRequest.callWebService(options)
        .then((result) => {
          if (result.data) {
            showSnackBar("Order updated successfully");
            setIsLoading(false);
            props.history.push(UIRoutes.ORDERS);
          }
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          props.history.push(UIRoutes.ORDERS);
          setIsLoading(false);
        });
    } else {
      let options = {
        method: "POST",
        url: webServiceRoutes.ADD_ORDER,
        data: formData,
      };
      setIsLoading(true);
      /**
       * server call for add order
       */
      WebServiceRequest.callWebService(options)
        .then((result) => {
          if (result.data) {
            showSnackBar("Order added successfully");
            setIsLoading(false);
            props.history.push(UIRoutes.ORDERS);
          }
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          setIsLoading(false);
        });
    }
  };

  const styles = {
    buttons: {
      marginTop: 30,
      float: "right",
    },

    inputTextField: {
      height: 72,
      width: "48%",
      marginRight: 10,
    },
    placesAutocomplete: {
      height: 72,
      width: "48%",
      verticalAlign: "bottom",
    },
    googlePlacesInput: {
      position: "relative",
      border: "none",
      outline: "none",
      backgroundColor: "rgba(0, 0, 0, 0)",
      cursor: "inherit",
      font: "inherit",
      opacity: 1,
      height: 68,
      boxSizing: "border-box",
      borderSpacing: 0,
      width: "98%",
      marginRight: 10,
    },
    googlePlacesWrapper: {
      fontSize: 16,
      width: "48%",
      float: "left",
      position: "relative",
      backgroundColor: "transparent",
      fontFamily: "Roboto, sans-serif",
      transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
      borderSpacing: 0,
      cursor: "auto",
      marginRight: 10,
    },
    errorTextStyle: {
      float: "left",
    },
  };

  return (
    <div>
      <PageBase
        title={info ? "Order Information" : "Add Order"}
        navigation={info ? "Order Information" : "Add Order"}
      >
        {isLoading ? <Loader /> : null}
        <form autoComplete="off" onSubmit={handleSubmit}>
          <br />
          <br />
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "300",
              marginBottom: "20px",
            }}
          >
            User Details
          </h3>
          <hr
            style={{
              margin: "-1px 0px 0px",
              height: "1px",
              border: "none",
              backgroundColor: "rgb(224, 224, 224)",
            }}
          />
          {info ? (
            <TextField
              floatingLabelText="Email"
              value={userEmail}
              style={styles.inputTextField}
              errorStyle={styles.errorTextStyle}
              required
            />
          ) : (
            <AutoComplete
              floatingLabelText="Email"
              filter={AutoComplete.caseInsensitiveFilter}
              errorStyle={styles.errorTextStyle}
              style={styles.inputTextField}
              errorText={
                userStatus === "verified"
                  ? null
                  : "Selected user is not verified."
              }
              required
              fullWidth={true}
              openOnFocus={true}
              dataSource={emails}
              onUpdateInput={debounce((e) => {
                handleChange(e, "email");
              }, 1000)}
              onNewRequest={(e) => {
                setFirstName(
                  users.filter((item) => item.email === e)[0].first_name
                );
                setLastName(
                  users.filter((item) => item.email === e)[0].last_name
                );
                setTelephone(
                  users.filter((item) => item.email === e)[0].telephone
                );
                setUserAddress(
                  users.filter((item) => item.email === e)[0].address
                );
                setUserStatus(
                  users.filter((item) => item.email === e)[0]
                    .verification_status
                );
                setUserEmail(users.filter((item) => item.email === e)[0].email);
                setUserId(users.filter((item) => item.email === e)[0].id);
                handleChange(
                  users.filter((item) => item.email === e)[0].id,
                  "userUnits"
                );
              }}
            />
          )}

          <TextField
            floatingLabelText="Telephone"
            value={telephone}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="First Name"
            value={firstName}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Last Name"
            value={lastName}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <PlacesAutocomplete value={userAddress}
            onChange={e=> setUserAddress(e)}>
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={styles.googlePlacesWrapper}>
                <input
                  {...getInputProps({
                    placeholder: "Address",
                    floatingLabelText: "Address",
                    className: "location-search-input",
                  })}
                  style={styles.googlePlacesInput}
                />
                <div
                  className="autocomplete-dropdown-container"
                  style={{
                    padding: 0,
                    position: "absolute",
                    width: "100%",
                    zIndex: 10,
                    borderBottom: "1px solid rgb(224, 224, 224)",
                    borderSpacing: 0,
                  }}
                >
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="error-text">{get(error, "address", "")}</div>
              </div>
            )}
          </PlacesAutocomplete>
          <br />
          <br />
          <br />
          <br />
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "300",
              marginBottom: "20px",
            }}
          >
            Unit Details
          </h3>
          <hr
            style={{
              margin: "-1px 0px 0px",
              height: "1px",
              border: "none",
              backgroundColor: "rgb(224, 224, 224)",
            }}
          />

          {info ? (
            <TextField
              floatingLabelText="Unit Number"
              value={unitNum}
              style={styles.inputTextField}
              errorStyle={styles.errorTextStyle}
              required
            />
          ) : (
            <AutoComplete
              errorStyle={styles.errorTextStyle}
              errorText={
                product_distributor &&
                unit_distributor &&
                product_distributor.toUpperCase() !=
                  unit_distributor.toUpperCase()
                  ? "Unit Distributor & Product Distributor Mismatch"
                  : null
              }
              onUpdateInput={(text)=> setUnitNum(text)}
              searchText={unitNum}
              hintText="Enter unit number"
              floatingLabelText="Unit Number"
              filter={AutoComplete.caseInsensitiveFilter}
              style={styles.inputTextField}
              fullWidth={true}
              openOnFocus={true}
              dataSource={unitNumbers}
              onNewRequest={(e) => {
                setInfoUnits_Id(
                  userUnits.filter((items) => items.unit_number === e)[0].id
                );
                setNickName(
                  userUnits.filter((item) => item.unit_number === e)[0].nickName
                );
                setIsDeleted(
                  userUnits.filter((item) => item.unit_number === e)[0]
                    .isDeleted
                );
                setUnit_group_voltage(
                  userUnits.filter((item) => item.unit_number === e)[0]
                    .group_voltage
                );
                setUnit_power_plant(
                  userUnits.filter((item) => item.unit_number === e)[0]
                    .power_plant
                );
                setPhase(
                  userUnits.filter((item) => item.unit_number === e)[0].phase
                );
                setUnitAddress(
                  userUnits.filter((item) => item.unit_number === e)[0].address
                );
                setContract(
                  userUnits.filter((item) => item.unit_number === e)[0].contract
                );
                setUnitNum(
                  userUnits.filter((item) => item.unit_number === e)[0]
                    .unit_number
                );
                setCnpj(
                  userUnits.filter((item) => item.unit_number === e)[0].cnpj
                );
                setUnit_distributor(
                  userUnits.filter((item) => item.unit_number === e)[0]
                    .distributor
                );
              }}
            />
          )}

          <TextField
            floatingLabelText="Nick Name"
            value={nickName}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="isDeleated"
            value={isDeleted}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Group Voltage"
            value={unit_group_voltage}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Power Plant"
            value={unit_power_plant}
            onChange={e=> setUnit_power_plant(e.target.value)}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
          />
          <TextField
            floatingLabelText="Phase"
            value={phase}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Address"
            value={unitAddress}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Contract"
            value={contract}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            onChange={e=>setContract(e.target.value)}
          />
          <br />
          <TextField
            floatingLabelText="CNPJ"
            value={cnpj}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Distributor"
            value={unit_distributor}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <br />
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "300",
              marginBottom: "20px",
            }}
          >
            Product Details
          </h3>
          <hr
            style={{
              margin: "-1px 0px 0px",
              height: "1px",
              border: "none",
              backgroundColor: "rgb(224, 224, 224)",
            }}
          />

          {info ? (
            <TextField
              floatingLabelText="Product Category"
              value={productCategory}
              style={styles.inputTextField}
              errorStyle={styles.errorTextStyle}
              required
            />
          ) : (
            <AutoComplete
              floatingLabelText="Product Category"
              filter={AutoComplete.caseInsensitiveFilter}
              style={styles.inputTextField}
              fullWidth={true}
              openOnFocus={true}
              dataSource={categoryList}
              onNewRequest={(e) => {
                setCategoryId(
                  categories.filter((item) => item.title === e)[0].categoryId
                );
                setProductCategory(e);
                handleChange(
                  categories.filter((item) => item.title === e)[0].categoryId,
                  "product"
                );
              }}
            />
          )}

          {info ? (
            <TextField
              floatingLabelText="Product Name"
              value={productName}
              style={styles.inputTextField}
              errorStyle={styles.errorTextStyle}
              required
            />
          ) : (
            <AutoComplete
              floatingLabelText="Product Name"
              filter={AutoComplete.caseInsensitiveFilter}
              style={styles.inputTextField}
              fullWidth={true}
              openOnFocus={true}
              dataSource={productNames}
              onNewRequest={(e) => {
                setProductName(e);
                setProduct_id(
                  products.filter((items) => items.name === e)[0].productId
                );
                setUnique_code(
                  products.filter((item) => item.name === e)[0].unique_code
                );
                setDiscount(
                  products.filter((item) => item.name === e)[0].discount
                );
                setProduct_distributor(
                  products.filter((item) => item.name === e)[0].distributor
                );
                setTotalAvailable_KWH(
                  products.filter((item) => item.name === e)[0]
                    .total_available_kwh
                );
                setMinimum_months(
                  products.filter((item) => item.name === e)[0].minimum_months
                );
                setOperation_start_month(
                  products.filter((item) => item.name === e)[0]
                    .operation_start_month
                );
                setMinimum_KWH(
                  products.filter((item) => item.name === e)[0]
                    .minimum_amount_kwh
                );
                setMaximum_KWH(
                  products.filter((item) => item.name === e)[0]
                    .maximum_amount_kwh
                );
                setGroupVoltage(
                  products.filter((item) => item.name === e)[0].group_voltage
                );
                setFullRate(
                  products.filter((item) => item.name === e)[0].full_rate
                );
                setReferenceRate(
                  products.filter((item) => item.name === e)[0].reference_rate
                );
                setDistributorLabel(
                  products.filter((item) => item.name === e)[0].app_distributor
                    .label
                );
                setProduct_address(
                  products.filter((item) => item.name === e)[0].address
                );
              }}
            />
          )}

          <br />
          <TextField
            floatingLabelText="Distributor"
            value={product_distributor}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Discount"
            value={discount}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Total Available KWH"
            value={totalAvailable_KWH}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Minimum Months"
            value={minimum_months}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Operation Start Month"
            value={operation_start_month}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Minimum amount of KWH"
            value={minimum_KWH}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Maximum amount of KWH"
            value={maximum_KWH}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Group Voltage"
            value={groupVoltage}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Full Rate"
            value={fullRate}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Reference Rate"
            value={referenceRate}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <br />
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "300",
              marginBottom: "20px",
            }}
          >
            Order Details
          </h3>
          <hr
            style={{
              margin: "-1px 0px 0px",
              height: "1px",
              border: "none",
              backgroundColor: "rgb(224, 224, 224)",
            }}
          />

          <TextField
            errorText={
              consumption_kwh &&
              (consumption_kwh > maximum_KWH ||
                consumption_kwh < minimum_KWH ||
                consumption_kwh > totalAvailable_KWH)
                ? "Value Should Be In Range !"
                : null
            }
            floatingLabelText="Consumption (kWh)"
            value={consumption_kwh}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            onChange={(e) => {
              !info &&
                productName &&
                setConsumption_kwh(Number(e.target.value));
            }}
            required
          />
          <TextField
            floatingLabelText="Perceived Discount"
            value={perceived_discount.toFixed(4)}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <TextField
            floatingLabelText="Monthly Equivalent"
            value={monthlyEquivalent}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Availed Discount"
            value={availedDiscount}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <SelectField
            value={contract_status}
            style={{ ...styles.inputTextField }}
            onChange={(e, index, value) => {
              setContract_status(value);
            }}
            floatingLabelText="Contract Status"
          >
            {contract_status_list.map((elem) => {
              return <MenuItem key={elem} value={elem} primaryText={elem} />;
            })}
          </SelectField>
          <br />

          <div style={styles.buttons}>
            <Link to="/orders">
              <RaisedButton label="Cancel" />
            </Link>

            <RaisedButton
              disabled={
                distributorMismatch ||
                consumption_error ||
                userStatus !== "verified"
                  ? true
                  : false
              }
              type="submit"
              label="Save"
              style={styles.saveButton}
              primary={true}
            />
          </div>
        </form>
      </PageBase>
    </div>
  );
}
