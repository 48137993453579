import React, { useState } from "react";
import PageBase from "../components/PageBase";

import TextField from "material-ui/TextField";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
import { webServiceRoutes, UIRoutes } from "../config/constants";
import get from "lodash/get";

export default function AddDistributor(props) {

  const [label, setLabel] = useState(get(props,"location.state.label",""));
  const [name, setName] = useState(get(props,"location.state.name",""));
  const [isLoading, setisLoading] = useState(false);

  let edit = get(props, "location.state.edit", false)
  let url, method;

  let form = {
    label,
    name
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (edit){
      url = webServiceRoutes.EDIT_DISTRIBUTOR + "/" + props.location.state.id;
      method= "PUT"
    }
    else {
      url = webServiceRoutes.ADD_DISTRIBUTOR;
      method = "POST"
    }

    let options = {
      method: method,
      url: url,
      data: form,
    };
    setisLoading(true);
    /**
     * server call for add/edit distributor
     */
    WebServiceRequest.callWebService(options)
      .then((result) => {
        if (result.data) {
          {edit? showSnackBar("Distributor edited successfully") : showSnackBar("Distributor added successfully") };
          setisLoading(false);
          props.history.push(UIRoutes.DISTRIBUTORS);
        }
      })
      .catch((e) => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        setisLoading(false);
      });
  };

  const styles = {
    buttons: {
      marginTop: 30,
      float: "right",
    },
    saveButton: {
      marginLeft: 5,
    },
    inputTextField: {
      height: 72,
      width: "48%",
      marginRight: 10,
    },
    errorTextStyle: {
      float: "left",
    },
  };

  return (
    <div>
      <PageBase title={edit ?"Edit Distributor": "Add Distributor"} navigation={edit ?"Edit Distributor": "Add Distributor"}>
        {isLoading ? <Loader /> : null}
        <form autoComplete="off" onSubmit={handleSubmit}>
          <br />
          <TextField
            floatingLabelText="Label"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <br />
          <TextField
            floatingLabelText="Name"
            disabled= {edit? true : false}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <br />
          <div style={styles.buttons}>
            <Link to="/distributors">
              <RaisedButton label="Cancel" />
            </Link>

            <RaisedButton
              type="submit"
              label="Save"
              style={styles.saveButton}
              primary={true}
            />
          </div>
        </form>
      </PageBase>
    </div>
  );
}
