import React from "react";
import { withRouter } from "react-router-dom";
import { grey400 } from "material-ui/styles/colors";
import get from "lodash/get";
import set from "lodash/set";

import PageBase from "../components/PageBase";
import { webServiceRoutes } from "../config/constants";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import ImportLogsData from "../components/ImportLogsData";
import { Loader } from "../components/Loader";

const styles = {
  toggleDiv: {
    maxWidth: 300,
    marginTop: 40,
    marginBottom: 5
  },
  toggleLabel: {
    color: grey400,
    fontWeight: 100
  },
  buttons: {
    marginTop: 30,
    float: "right"
  },
  saveButton: {
    marginLeft: 5
  },
  googlePlacesInput: {
    padding: 0,
    position: "relative",
    border: "none",
    outline: "none",
    backgroundColor: "rgba(0, 0, 0, 0)",
    cursor: "inherit",
    font: "inherit",
    opacity: 1,
    height: 55,
    boxSizing: "border-box",
    width: "100%",
    marginRight: 10
  },
  googlePlacesWrapper: {
    fontSize: 16,
    width: "48%",
    height: 47,
    float: "left",
    position: "relative",
    backgroundColor: "transparent",
    fontFamily: "Roboto, sans-serif",
    transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    cursor: "auto",
    borderBottom: "1px solid rgb(224, 224, 224)"
  },
  inputTextField: {
    height: 55,
    width: "48%",
    marginRight: 10
  },
  cnpjTextField: {
    marginRight: 0,
    marginLeft: 10,
    height: 55,
    width: "48%"
  },
  columns: {
    width_100: {
      width: "100px"
    }
  },
  importButton: {
    cursor: "pointer",
    padding: "12px 18px",
    border: 0,
    color: "#ffffff",
    backgroundColor: "rgb(41, 103, 134)",
    fontSize: "14px",
    float: "right"
  },
  fileInput: {
    padding: "6px 0px 6px 9px"
  }
};

class ImportUnitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      importLog: {},
      error: {},
      isShowButtons: true,
      isLoading: false,
      selectedFile: null,
      errSelectedFile: ""
    };
  }

  /**
   * on selecting data file file
   * @param {object} fileObject
   */
  handleselectedFile = event => {
    this.setState({
      selectedFile: event.target.files[0],
      errSelectedFile: ""
    });
  };

  /**
   * upload file on server
   * @param {object} e
   */
  handleUpload = e => {
    e.preventDefault();
    if (!this.state.selectedFile) {
      this.setState({ errSelectedFile: "File is required" });
    } else {
      const id = this.props.match.params.id;
      const data = new FormData();
      data.append("user_id", id);
      data.append(
        "myUnitsFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
      const params = {
        url: webServiceRoutes.IMPORT_UNITS,
        method: "POST",
        data
      };
      this.setState({ isLoading: true });
      /**
       * server call for file upload
       */
      WebServiceRequest.callWebService(params)
        .then(result => {
          showSnackBar("Importing in progress");
          const data = JSON.stringify(result);
          set(this.state, "importLog", data);
          this.fileInput.value = "";
          set(this.state, "selectedFile", "");
          set(this.state, "isLoading", false);
          this.setState(this.state);
        })
        .catch(e => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          this.setState({ isLoading: false });
        });
    }
  };

  /**
   * render import unit page
   */
  render() {
    const { form, isLoading } = this.state;
    const id = this.props.match.params.id;
    const loggedInUserId = get(this.props, "user.id", "");
    return (
      <PageBase title="Import Units" navigation="Import Units">
        {isLoading ? <Loader /> : null}
        <form action="post" enctype="multipart/form-data">
          <div style={{ padding: "15px 0px" }}>
            <div
              style={{
                display: "inline-flex",
                border: "1px solid rgb(117, 117, 117)",
                borderRadius: "2px",
                alignItems: "center"
              }}
            >
              <input
                type="file"
                id="myUnitsFile"
                name="myUnitsFile"
                style={{
                  ...styles.fileInput,
                  cursor: "pointer",
                  color: this.state.errSelectedFile ? "rgb(244, 67, 54)" : ""
                }}
                accept=".csv, .xls, .xlsx"
                onChange={this.handleselectedFile}
                ref={ref => (this.fileInput = ref)}
                // value={this.state.selectedFile}
              />
              <button
                type="submit"
                style={styles.importButton}
                onClick={this.handleUpload}
              >
                Submit
              </button>
            </div>
            <div
              style={{
                fontSize: "12px",
                lineHeight: "12px",
                color: "rgb(244, 67, 54)",
                margin: "3px 0px 0px 12px"
                // padding: "5px 0px 5px 9px"
              }}
            >
              {this.state.errSelectedFile}
            </div>
          </div>

          {loggedInUserId !== id ? (
            <ImportLogsData id={id} importLog={this.state.importLog} />
          ) : null}
        </form>
      </PageBase>
    );
  }
}

export default withRouter(ImportUnitPage);
