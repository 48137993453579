import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Route } from "react-router-dom";
import { LARGE, SMALL } from "material-ui/utils/withWidth";
import get from "lodash/get";
import "./App.css";
import Header from "./components/Header";
import LeftDrawer from "./components/LeftDrawer";
import DashboardPage from "./containers/DashboardPage";
import PendingUsers from "./containers/PendingUsers";
import ApprovedUsers from "./containers/ApprovedUsers";
import AddUser from "./containers/AddUserPage";
import AddCategory from "./containers/AddCategoryPage";
import ChangePassword from "./containers/ChangePassword";
import RejectedUsers from "./containers/RejectedUsers";
import DetailPage from "./containers/DetailPage";
import ThemeDefault from "./theme-default";
import Data from "./data";
import { isLoggedIn } from "./util/methods";
import Snackbar from "./util/components/snackbar/Snackbar";
import ImportUnitPage from "./containers/ImportUnitPage";
import Category from "./components/categories";
import Distributor from "./components/Distributor";
import AddDistributor from "./containers/AddDistributor";
import Product from "./components/Product";
import AddProduct from "./containers/AddProduct";
import Review from "./containers/Review";
import Order from "./components/orders";
import AddOrder from "./containers/AddOrder";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navDrawerOpen: true,
    };
  }

  componentWillMount() {
    /**
     * check is admin loggedIn
     */
    isLoggedIn()
      .then((user) => {
        user = get(user, "data", {});
        if (user.role === "admin") {
          this.setState({ user: user });
        } else {
          this.props.history.push("/login");
        }
      })
      .catch((e) => {
        console.log(e);
        this.props.history.push("/login");
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setState({ navDrawerOpen: nextProps.width === LARGE });
    }
  }

  /**
   * toggle side menu
   */
  handleChangeRequestNavDrawer() {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen,
    });
  }

  /**
   * render DashBoard page
   */
  render() {
    let { navDrawerOpen, user } = this.state;
    const paddingLeftDrawerOpen = 236;

    const styles = {
      header: {
        paddingLeft: navDrawerOpen ? paddingLeftDrawerOpen : 0,
      },
      container: {
        margin: "0px 20px 20px 15px",
        paddingTop: 80,
        paddingLeft:
          navDrawerOpen && this.props.width !== SMALL
            ? paddingLeftDrawerOpen
            : 0,
      },
    };

    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <div>
          <Header
            styles={styles.header}
            handleChangeRequestNavDrawer={this.handleChangeRequestNavDrawer.bind(
              this
            )}
            user={user}
          />

          <LeftDrawer
            navDrawerOpen={navDrawerOpen}
            menus={Data.menus}
            username="User Admin"
          />

          <div style={styles.container}>
            <Route exact path="/" component={DashboardPage} />
            <Route exact path="/pendingUsers" component={PendingUsers} />
            <Route exact path="/approvedUsers" component={ApprovedUsers} />
            <Route exact path="/addUser" component={AddUser} />
            <Route exact path="/changePassword" component={ChangePassword} />
            <Route exact path="/rejectedUsers" component={RejectedUsers} />
            <Route exact path="/categories" component={Category} />
            <Route exact path={["/addCategory","/editCategory"]} component={AddCategory} />
            <Route exact path="/distributors" component={Distributor} />
            <Route exact path={["/addDistributor","/editDistributor"]} component={AddDistributor} />
            <Route exact path="/products" component={Product} />
            <Route exact path={["/addProduct", "/editProduct"]} component={AddProduct} />
            <Route exact path={"/review"} component={Review} />

            <Route exact path="/orders" component={Order} />
            <Route
              exact
              path={["/addOrder", "/OrderInformation"]}
              component={AddOrder}
            />
            <Route
              exact
              path="/user/:id"
              render={(props) => <DetailPage user={user} />}
            />
            <Route
              exact
              path="/importUnits/:id"
              render={(props) => <ImportUnitPage user={user} />}
            />
          </div>
        </div>
        <Snackbar />
      </MuiThemeProvider>
    );
  }
}

export default App;
