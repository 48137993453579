import React from "react";
import get from "lodash/get";
import { WebServiceRequest } from "../util/webRequest";
import { webServiceRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
// import { Loader } from "./Loader";
import Table from "./Table";
import { Link } from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { pink500 } from "material-ui/styles/colors";
import PageBase from "./PageBase";

export default function categories(props) {
  const filters = {};
  const columns = [
    {
      columnAlias: "Title",
      cell: (row) => {
        return row.title;
      },
    },
    {
      columnAlias: "Subtitle",
      cell: (row) => {
        return row.subtitle;
      },
    },
    // {
    //   columnAlias: "Active",
    //   cell: (row) => {
    //     return row.isActive;
    //   },
    // }
  ];

  const data_url = webServiceRoutes.CATEGORY_LIST;

  const EditForm = (item) => {
    props.history.push({
      pathname: "/editCategory",
      state: { ...item},
    });
  };
  const activeCategory = (item) => {
    props.history.push({
      pathname: "/editCategory",
      state: { ...item},
    });
  };
  const DeleteCategory = async (item) => {
    const params = {
      url: webServiceRoutes.DELETE_CATEGORY + item.categoryId,
      method: "delete",
      data: {},
    };

    try {
      const result = await WebServiceRequest.callWebService(params);
      let successMessage = get(result, "data.message");
      successMessage && showSnackBar(successMessage);
    } catch (e) {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
    }
  };
  const isActive = async (item,value) => {
    item.isActive=value
    const params = {
      url: webServiceRoutes.ISACTIVE_CATEGORY + item.categoryId,
      method: "put",
      data: item,
    };

    try {
      const result = await WebServiceRequest.callWebService(params);
      let successMessage = get(result, "data.message");
      successMessage && showSnackBar(successMessage);
    } catch (e) {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
    }
  };

  const styles = {
    floatingActionButton: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <PageBase title="Categories" navigation="Categories">
        <div>
          <Link to="/addCategory">
            <FloatingActionButton
              style={styles.floatingActionButton}
              backgroundColor={pink500}
            >
              <ContentAdd />
            </FloatingActionButton>
          </Link>
        </div>
        <Table
          actions={true}
          delete={DeleteCategory}
          edit={EditForm}
          data_url={data_url}
          filters={filters}
          columns={columns}
          toggleAction={true}
          toggle={isActive}
        />
      </PageBase>
    </div>
  );
}
