import React, { Component } from "react";

class LoaderComponent extends Component {
  render() {
    return (
      <div className="overlay">
        <div className="loader" />
      </div>
    );
  }
}

export const Loader = LoaderComponent;
