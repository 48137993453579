import React from "react";
import Drawer from "material-ui/Drawer";
import { spacing, typography } from "material-ui/styles";
import { white } from "material-ui/styles/colors";
import MenuItem from "material-ui/MenuItem";
import { Link } from "react-router-dom";
import Avatar from "material-ui/Avatar";

const styles = {
  logo: {
    cursor: "pointer",
    fontSize: 22,
    color: typography.textFullWhite,
    lineHeight: `${spacing.desktopKeylineIncrement}px`,
    fontWeight: typography.fontWeightLight,
    backgroundColor: "#296786",
    paddingLeft: 40,
    height: 56
  },
  menuItem: {
    color: white,
    fontSize: 14
  },
  avatar: {
    div: {
      padding: "15px 0 20px 15px",
      height: 45
    },
    icon: {
      float: "left",
      display: "block",
      marginRight: 15,
      boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.2)"
    },
    span: {
      paddingTop: 12,
      display: "block",
      color: "white",
      fontWeight: 300,
      textShadow: "1px 1px #444"
    }
  },
  logoImageStyle: {
    maxWidth: 411,
    maxHeight: 55
  }
};
class LeftDrawer extends React.Component {
  render() {
    let { navDrawerOpen } = this.props;
    let logo = "../../assets/logo.png";
    let defaultUser = "../../assets/defaultUser.png";
    return (
      <Drawer docked={true} open={navDrawerOpen}>
        <div style={styles.logo}>
          <img
            src={logo}
            alt="Logo"
            className="logo-image"
            style={styles.logoImageStyle}
          />
        </div>
        <div style={styles.avatar.div}>
          <Avatar src={defaultUser} size={50} style={styles.avatar.icon} />
          <span style={styles.avatar.span}>{this.props.username}</span>
        </div>
        <div>
          {this.props.menus.map((menu, index) => (
            <MenuItem
              key={index}
              style={styles.menuItem}
              primaryText={menu.text}
              leftIcon={menu.icon}
              containerElement={<Link to={menu.link} />}
            />
          ))}
        </div>
      </Drawer>
    );
  }
}

// LeftDrawer.propTypes = {
//   navDrawerOpen: PropTypes.bool,
//   menus: PropTypes.array,
//   username: PropTypes.string
// };

export default LeftDrawer;
