import React, { useState, useEffect } from "react";
import PageBase from "../components/PageBase";

import TextField from "material-ui/TextField";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
import { webServiceRoutes, UIRoutes } from "../config/constants";
import get from "lodash/get";

export default function AddCategory(props) {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (props.location.state) {
      setEdit(true);
      let previousTitle = props.location.state.title;
      let previousSubtitle = props.location.state.subtitle;
      let previousDescription = props.location.state.description;
      let categoryId = props.location.state.categoryId;

      setCategoryId(categoryId);
      setTitle(previousTitle);
      setSubtitle(previousSubtitle);
      setDescription(previousDescription);
    }
  }, []);

  let form = {
    title,
    subtitle,
    description,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      let options = {
        method: "PUT",
        url: webServiceRoutes.UPDATE_CATEGORY + categoryId,
        data: form,
      };
   
      setIsLoading(true);
      WebServiceRequest.callWebService(options)
        .then((result) => {
          if (result.data) {
            showSnackBar("Category updated successfully");
            setIsLoading(false);
            props.history.push(UIRoutes.CATEGORIES);
          }
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          setIsLoading(false);
        });
    } else {
      let options = {
        method: "POST",
        url: webServiceRoutes.ADD_CATEGORY,
        data: form,
      };
      setIsLoading(true);
      /**
       * server call for add category
       */
      WebServiceRequest.callWebService(options)
        .then((result) => {
          if (result.data) {
            showSnackBar("Category added successfully");
            setIsLoading(false);
            props.history.push(UIRoutes.CATEGORIES);
          }
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          setIsLoading(false);
        });
    }
  };

  const styles = {
    buttons: {
      marginTop: 30,
      float: "right",
    },
    saveButton: {
      marginLeft: 5,
    },
    inputTextField: {
      height: 72,
      width: "48%",
      marginRight: 10,
    },
    errorTextStyle: {
      float: "left",
    },
  };

  return (
    <div>
      <PageBase
        title={edit ? "Edit Category" : "Add Category"}
        navigation={edit ? "Edit Category" : "Add Category"}
      >
        {isLoading ? <Loader /> : null}
        <form autoComplete="off" onSubmit={handleSubmit}>
          <br />
          <TextField
            floatingLabelText="Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <br />
          <TextField
            floatingLabelText="Subtitle"
            onChange={(e) => {
              setSubtitle(e.target.value);
            }}
            style={styles.inputTextField}
            value={subtitle}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <br />
          <TextField
            id="standard-multiline-static"
            floatingLabelText="Description"
            multiLine
            value={description}
            rows={5}
            style={{ width: "48%" }}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required
          />

          <br />
          <br />
          <div style={styles.buttons}>
            <Link to="/categories">
              <RaisedButton label="Cancel" />
            </Link>

            <RaisedButton
              type="submit"
              label="Save"
              style={styles.saveButton}
              primary={true}
            />
          </div>
        </form>
      </PageBase>
    </div>
  );
}
