import React, { useState, useEffect } from "react";
import PageBase from "../components/PageBase";

import TextField from "material-ui/TextField";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
import { webServiceRoutes, UIRoutes } from "../config/constants";
import get from "lodash/get";


export default function AddDistributor(props) {
  const [imageUrls, setImageUrls] = useState([]);
  const [pdfUrls, setPdfUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(props.location.state.image_url) setImageUrls(props.location.state.image_url.split(","));
    if(props.location.state.pdf_url) setPdfUrls(props.location.state.pdf_url.split(","));
  }, []);

  let url, method;


  const handleAction = async action => {
    if (action === "reject") {
      url = webServiceRoutes.REJECT_DOCUMENT + "/" + props.location.state.id;
      method = "PUT";
    } else {
      url = webServiceRoutes.VERIFY_DOCUMENT + "/" + props.location.state.id;
      method = "PUT";
    }
    let options = {
      method: method,
      url: url,
    };
    setIsLoading(true);
    try {
      const result = await WebServiceRequest.callWebService(options);
      {
        action === "reject"
          ? showSnackBar("Document rejected successfully")
          : showSnackBar("Document verified successfully");
      }
      setIsLoading(false);
      props.history.push(UIRoutes.APPROVED);
    } catch (e) {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
      setIsLoading(false);
    }
  };

  const styles = {
    buttons: {
      marginTop: 30,
      float: "right",
    },
    cancelButtons: {
      marginTop: 30,
      marginLeft: 5,
      float: "right",
    },
    saveButton: {
      marginLeft: 5,
    },
    inputTextField: {
      height: 72,
      width: "48%",
      marginRight: 10,
    },
    errorTextStyle: {
      float: "left",
    },
  };

  return (
    <div>
      <PageBase title='Review Document' navigation='Review Document'>
        {isLoading ? <Loader /> : null}
        {imageUrls && (imageUrls.map(url => (
          <div>
            <br/>
            <br/>
            <img
              src={url}
              style={{ width: "48%"}}
            />
            <br/>
            <br/>
            <br/>
            <hr style={{margin: "-1px 0px 0px", height: "1px", border: "none",backgroundColor: "rgb(224, 224, 224)"}}></hr>
          </div>
        )))}
        
        {pdfUrls && (pdfUrls.map(url => 
          (
           <div>
           <br/>
           <br/>
           <embed
             src={url}
             type='application/pdf'
             width="49%" 
            height="700px" 
           />
           <br/>
          <br/>
          <br/>
            <hr style={{margin: "-1px 0px 0px", height: "1px", border: "none",backgroundColor: "rgb(224, 224, 224)"}}></hr>
         </div>
        )))}
       

        <div style={styles.buttons}>
          <RaisedButton
            label='Reject'
            onClick={e => {
              handleAction("reject");
            }}
          />

          <RaisedButton
            label='Verify'
            style={styles.saveButton}
            primary={true}
            onClick={e => {
              handleAction("verify");
            }}
          />
          <Link to='/approvedUsers'>
            <RaisedButton style={styles.saveButton} label='Back' />
          </Link>
        </div>
      </PageBase>
    </div>
  );
}
