import React from "react";
import { Link } from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { pink500 } from "material-ui/styles/colors";

import PageBase from "../components/PageBase";
import TableComponent from "../components/TableComponent";

const styles = {
  floatingActionButton: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed"
  }
};

class RejectedUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  /**
   * render rejected users page
   */
  render() {
    return (
      <div style={{ position: "relative" }}>
        <PageBase title="Rejected Users" navigation="Rejected Users">
          <div>
            <Link to="/addUser">
              <FloatingActionButton
                style={styles.floatingActionButton}
                backgroundColor={pink500}
              >
                <ContentAdd />
              </FloatingActionButton>
            </Link>
          </div>
          <TableComponent status="rejected" />
        </PageBase>
      </div>
    );
  }
}

export default RejectedUsers;
