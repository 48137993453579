import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { grey500, white } from "material-ui/styles/colors";
import TextField from "material-ui/TextField";
import { Link } from "react-router-dom";
import set from "lodash/set";
import get from "lodash/get";
import ThemeDefault from "../../theme-default";
import { UIRoutes, webServiceRoutes } from "../../config/constants";
import { WebServiceRequest } from "../../util/webRequest";
import Snackbar, {
  showSnackBar
} from "../../util/components/snackbar/Snackbar";

const styles = {
  container: {
    minWidth: 320,
    maxWidth: 400,
    height: "auto",
    position: "absolute",
    top: "20%",
    left: 0,
    right: 0,
    margin: "auto"
  },
  paper: {
    padding: "50px 26px",
    overflow: "auto"
  },
  buttonsDiv: {
    textAlign: "center",
    padding: 10
  },
  flatButton: {
    color: grey500,
    height: "22px",
    lineHeight: "20px",
    fontSize: "9px",
    marginBottom: "28px",
    marginTop: "20px",
    float: "right"
  },
  labelStyle: {
    fontSize: "12px",
    paddingLeft: "0px"
  },
  checkRemember: {
    style: {
      float: "left",
      maxWidth: 180,
      paddingTop: 5
    },
    labelStyle: {
      color: grey500
    },
    iconStyle: {
      color: grey500,
      borderColor: grey500,
      fill: grey500
    }
  },
  loginBtn: {
    float: "right"
  },
  btn: {
    background: "#4f81e9",
    color: white,
    padding: 7,
    borderRadius: 2,
    margin: 2,
    fontSize: 13
  },
  btnSpan: {
    marginLeft: 5
  },
  h2: {
    margin: 0,
    color: "#333f48",
    fontSize: "24px",
    fontWeight: 400,
    paddingBottom: "52px",
    textAlign: "center"
  },
  logoImageStyle: {
    maxWidth: 411,
    maxHeight: 55
  }
};
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      form: {}
    };
  }

  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, `form.${field}`, value);
    set(this.state, `error.${field}`, null);
    this.setState(this.state);
  };

  /**
   * validate forgot form data
   */
  validateForm() {
    return true;
  }

  /**
   * on click send email button button
   */
  onSendEmailButtonClick = () => {
    if (!this.validateForm()) {
      return;
    }

    const { form } = this.state;
    let options = {
      method: "POST",
      url: webServiceRoutes.FORGOT_PASSWORD,
      data: form
    };

    /**
     * server call for reset password
     */
    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data.success) {
          let successMessage = get(result, "data.message");
          console.log(successMessage);
          successMessage && showSnackBar(successMessage);
        }
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
      });
  };

  /**
   * render forgot password page
   */
  render() {
    const logo = "../../../assets/logo.png";
    const { form } = this.state;
    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <div className="header">
          <div className="container">
            <div className="header-wraper">
              <div className="headerLogo">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo-image"
                  style={styles.logoImageStyle}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={styles.container}>
            <Paper style={styles.paper}>
              <h2 style={styles.h2}>Forgot Password</h2>
              <form>
                <TextField
                  hintText="Email Id"
                  floatingLabelText="Email Id"
                  fullWidth={true}
                  onChange={e => {
                    this.onChange("email", e.target.value);
                  }}
                  errorText={get(this.state, "error.email", "")}
                />

                <div>
                  <Link to={UIRoutes.LOGIN}>
                    <FlatButton
                      label="Back to login?"
                      style={styles.flatButton}
                      labelStyle={styles.labelStyle}
                    />
                  </Link>
                  <RaisedButton
                    label="Send Email"
                    primary={true}
                    fullWidth
                    style={styles.loginBtn}
                    onClick={this.onSendEmailButtonClick}
                  />
                </div>
              </form>
            </Paper>
          </div>
        </div>
        <Snackbar />
      </MuiThemeProvider>
    );
  }
}

export default ForgotPassword;
