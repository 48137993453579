import React from "react";
import { Link } from "react-router-dom";
import { cyan600, pink600 } from "material-ui/styles/colors";
import VerifiedUser from "material-ui/svg-icons/action/verified-user";
import PendingUser from "material-ui/svg-icons/action/update";
import get from "lodash/get";
import InfoBox from "../components/InfoBox";
import globalStyles from "../styles";
import { WebServiceRequest } from "../util/webRequest";
import { webServiceRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userCounts: {},
      isLoading: false
    };
  }
  componentWillMount() {
    this.setState({ isLoading: true });
    const params = {
      url: webServiceRoutes.COUNT,
      data: {},
      method: "GET"
    };
    /**
     * server call for userCounts
     */
    WebServiceRequest.callWebService(params)
      .then(result => {
        this.setState({ userCounts: result.data, isLoading: false });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
      });
  }
  /**
   * render DashBoard page
   */
  render() {
    const { isLoading } = this.state;
    return (
      <div>
        {isLoading ? <Loader /> : null}
        <h3 style={globalStyles.navigation}>Dashboard</h3>

        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 m-b-15 ">
            <Link to="/approvedUsers">
              <InfoBox
                Icon={VerifiedUser}
                color={pink600}
                title="Approved users"
                value={get(this.state, "userCounts.approved", 0)}
              />
            </Link>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 m-b-15 ">
            <Link to="/pendingUsers">
              <InfoBox
                Icon={PendingUser}
                color={cyan600}
                title="pending users"
                value={get(this.state, "userCounts.pending", 0)}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardPage;
