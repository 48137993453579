import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import App from "./App";
import Login from "./components/login/Login";
import ForgotPassword from "./components/forgot-password/ForgotPassword";

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route path="/" component={App} />
        </Switch>
      </div>
    );
  }
}

export default Routes;
