import React from "react";
import get from "lodash/get";
import { WebServiceRequest } from "../util/webRequest";
import { webServiceRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import Table from "./Table";
import { Link } from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { pink500 } from "material-ui/styles/colors";
import PageBase from "./PageBase";

export default function Distributor (props) {

  const filters={}
  const columns = [
    {
      columnAlias: "Label",
      cell: (row) => {
        return row.label;
      },
    },
    {
      columnAlias: "Name",
      cell: (row) => {
        return row.name;
      },
    }
  ];

  const data_url = webServiceRoutes.DISTRIBUTOR_LIST;

  const EditForm=(item)=> {
    props.history.push({
      pathname: '/editDistributor',
      state: {...item, edit:true}
    })
  }

  const DeleteDistributor = async (item) => {
    let options = {
      method: "DELETE",
      url: webServiceRoutes.DELETE_DISTRIBUTOR + '/' + item.distributor_id,
      data:{}
    };
    /**
     * server call to delete distributor
     */
    try{
      const result = await WebServiceRequest.callWebService(options)
      let successMessage = get(result, "data.message");
      successMessage && showSnackBar(successMessage);
    }
    catch(e){
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
    }
  }
  
  const styles = {
    floatingActionButton: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <PageBase title="Distributors" navigation="Distributors">
        <div>
          <Link to="/addDistributor">
            <FloatingActionButton
              style={styles.floatingActionButton}
              backgroundColor={pink500}
            >
              <ContentAdd />
            </FloatingActionButton>
          </Link>     
        </div>   
        <Table
          actions={true}
          edit={EditForm}
          delete={DeleteDistributor}
          data_url={data_url}
          filters={filters}
          columns={columns}
        />
      </PageBase>
    </div>
  );
}