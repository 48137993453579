import React from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";
import SearchBar from "material-ui-search-bar";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Toggle from "material-ui/Toggle";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { grey500 } from "material-ui/styles/colors";
import CheckCircle from "material-ui/svg-icons/action/check-circle";
import Cancel from "material-ui/svg-icons/navigation/cancel";
import Dialog from "material-ui/Dialog";
import get from "lodash/get";
import set from "lodash/set";
import findIndex from "lodash/findIndex";
import map from "lodash/map";

import Pagination from "../components/Pagination";
import { WebServiceRequest } from "../util/webRequest";
import { UIRoutes, webServiceRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
import { formatPhone } from "../util/methods";

const styles = {
  editButton: {
    fill: grey500
  },
  columns: {
    name: {
      width: "20%"
    },
    email: {
      width: "30%"
    },
    telephone: {
      width: "20%"
    },
    unitId: {
      width: "15%"
    },
    edit: {
      width: "20%"
    },
    approve: {
      width: "15%"
    },
    reject: {
      width: "15%"
    }
  },
  searchBarWrapper: {
    top: 53,
    position: "absolute",
    right: 30
  },
  searchBar: {
    margin: "0 auto",
    maxWidth: 500,
    float: "right",
    boxShadow: "none",
    border: "1px solid rgb(224, 224, 224)"
  }
};

const items = [
  <MenuItem value={null} primaryText="" />,
  <MenuItem key={1} value={true} primaryText="Active" />,
  <MenuItem key={2} value={false} primaryText="Inactive" />
];

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showCommentBox: false,
      reason: "",
      searchText: "",
      rowsPerPage: [5, 10, 15],
      numberOfRows: 5,
      page: 1,
      total: 0,
      statusFilter: "",
      selectedRow: [],
      isLoading: false,
      review:false
    };
    this.onPaginationFilterChange = this.onPaginationFilterChange.bind(this);
  }

  componentDidMount() {
    this.getUsersData();
  }

  /**
   * get user data
   */
  getUsersData = () => {
    const { page, numberOfRows, searchText, statusFilter } = this.state;
    this.setState({ isLoading: true });
    const params = {
      url: webServiceRoutes.USER_LIST_STATUS,
      params: {
        status: this.props.status,
        page: page || 1,
        per_page: numberOfRows || 10,
        q: searchText || "",
        isActive: statusFilter
      },
      method: "GET"
    };
    WebServiceRequest.callWebService(params)
      .then(result => {
        this.setState({
          users: get(result, "data.users", []),
          total: get(result, "data.count", 0),
          isLoading: false
        });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, field, value);
    this.setState(this.state);
  };

  /**
   * on click approve button
   * @param {string} id
   */
  onApproveButtonClicked = id => {
    this.setState({ isLoading: true });
    const params = {
      url: webServiceRoutes.APPROVE_USER + id,
      method: "PUT"
    };
    WebServiceRequest.callWebService(params)
      .then(result => {
        let successMessage = get(result, "data.message");
        successMessage && showSnackBar(successMessage);
        let users = get(this.state, "users", []);
        let index = findIndex(users, item => {
          return item.id === id;
        });
        if (index > -1) users.splice(index, 1);
        this.setState({ users, isLoading: false });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * on click reject button
   * @param {string} id
   */
  onRejectButtonClicked = id => {
    this.setState({ showCommentBox: true, rejectedId: id });
  };

  /**
   * on click close button
   */
  handleClose = () => {
    this.setState({ showCommentBox: false });
  };

  /**
   * on click submit button
   */
  handleSubmit = () => {
    const id = get(this.state, "rejectedId", null);
    const reason = get(this.state, "reason", null);
    this.setState({ isLoading: true });

    const params = {
      url: webServiceRoutes.Reject_USER + id,
      method: "PUT",
      data: { reason: reason }
    };
    WebServiceRequest.callWebService(params)
      .then(result => {
        let successMessage = get(result, "data.message");
        successMessage && showSnackBar(successMessage);
        let users = get(this.state, "users", []);
        let index = findIndex(users, item => {
          return item.id === id;
        });
        if (index > -1) users.splice(index, 1);
        this.setState({ users, isLoading: false });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
    this.setState({ showCommentBox: false });
  };

  /**
   * on change pagination filter
   * @param {object} updatedState
   */
  onPaginationFilterChange(updatedState) {
    const { numberOfRows, page } = updatedState;
    this.setState({ numberOfRows, page }, () => {
      this.getUsersData();
    });
  }

  /**
   * on click toggle
   * @param {string} value
   */
  onToggle = (id, value) => {
    let url = value
      ? webServiceRoutes.ACTIVATE_USER
      : webServiceRoutes.DEACTIVATE_USER;
    const params = {
      url: url + id,
      method: "PUT"
    };
    this.setState({ isLoading: true });

    WebServiceRequest.callWebService(params)
      .then(result => {
        let successMessage = get(result, "data.message");
        successMessage && showSnackBar(successMessage);
        let users = get(this.state, "users", []);
        let index = findIndex(users, item => {
          return item.id === id;
        });
        if (index > -1) {
          users[index].isActive = value;
        }
        this.setState({ users, isLoading: false });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * set status filter
   * @param {object} e
   * @param {string} index
   * @param {string} value
   */
  handleFilterStatus = (e, index, value) => {
    this.setState({ statusFilter: value }, () => {
      this.getUsersData();
    });
  };

  /**
   * on selecting row
   * @param {array} array
   */
  onRowSelection = (array, row) => {
    let selectedRow = [];
    const users = get(this.state, "users", []);
    if (array === "none") {
      selectedRow = [];
    } else if (array === "all") {
      for (let i = 0; i < users.length; i++) {
        users[i].isSelected = true;
      }
      selectedRow = map(users, "id");
    } else {
      for (let i = 0; i < users.length; i++) {
        users[i].isSelected = false;
        if (array.indexOf(i) > -1) {
          users[i].isSelected = true;
          selectedRow.push(users[i].id);
        }
      }
    }
    this.setState({ selectedRow, users });
  };

  /**
   * on click multi approve button
   */
  onMultiApproveButtonClicked = () => {
    const { selectedRow } = this.state;
    const params = {
      url: webServiceRoutes.MUlTI_APPROVE,
      method: "post",
      data: { ids: selectedRow }
    };
    this.setState({ isLoading: true });

    WebServiceRequest.callWebService(params)
      .then(result => {
        let successMessage = get(result, "data.message");
        successMessage && showSnackBar(successMessage);
        let users = get(this.state, "users", []);
        selectedRow.forEach(element => {
          let index = findIndex(users, item => {
            return item.id === element;
          });
          if (index > -1) users.splice(index, 1);
        });
        this.setState({ users, isLoading: false });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * render table component
   */
  render() {
    const users = get(this.state, "users", []);
    const status = get(this.props, "status", "");
    const open = get(this.state, "showCommentBox", false);
    const selectedRow = get(this.state, "selectedRow", []);
    const isLoading = get(this.state, "isLoading", false);
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton
        label="Submit"
        primary={true}
        disabled={!this.state.reason.length}
        onClick={this.handleSubmit}
      />
    ];
    return (
      <div>
        {isLoading ? <Loader /> : null}
        <div style={styles.searchBarWrapper}>
          {status === "approved" ? (
            <SelectField
              value={this.state.statusFilter}
              style={{ bottom: 17, right: 13 }}
              onChange={this.handleFilterStatus}
              floatingLabelText="Status"
            >
              {items}
            </SelectField>
          ) : null}

          {status === "pending" || status === "rejected" ? (
            <RaisedButton
              label="Approve"
              disabled={!selectedRow.length}
              style={{ marginTop: 5, marginRight: 5 }}
              onClick={this.onMultiApproveButtonClicked}
            />
          ) : null}

          <SearchBar
            onChange={e => this.onChange("searchText", e)}
            onRequestSearch={() => this.getUsersData()}
            style={styles.searchBar}
          />
        </div>
        <Table
          fixedHeader={true}
          multiSelectable={true}
          onRowSelection={this.onRowSelection}
          onCellClick={(row, columns) => {
            console.log(columns);
            if (columns !== 6 && columns !== -1) {
              this.props.history.push(UIRoutes.USER_DETAIL + users[row].id);
            }
          }}
        >
          <TableHeader>
            <TableRow >
              <TableHeaderColumn style={styles.columns.name}>
                Name
              </TableHeaderColumn>
              <TableHeaderColumn style={styles.columns.email}>
                Email
              </TableHeaderColumn>
              <TableHeaderColumn style={styles.columns.telephone}>
                Telephone
              </TableHeaderColumn>
              <TableHeaderColumn style={styles.columns.unitId}>
                Unit number
              </TableHeaderColumn>
              {status === "pending" || status === "rejected" ? (
                <TableHeaderColumn style={styles.columns.approve}>
                  Approve
                </TableHeaderColumn>
              ) : null}
              {status === "pending" ? (
                <TableHeaderColumn style={styles.columns.reject}>
                  Reject
                </TableHeaderColumn>
              ) : null}
              {status === "approved" ? (
                <TableHeaderColumn style={styles.columns.approve}>
                  Action
                </TableHeaderColumn>
              ) : null}
              <TableHeaderColumn style={styles.columns.unitId}>
                Document Status
              </TableHeaderColumn>
              <TableHeaderColumn style={styles.columns.unitId}>
                Document
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody deselectOnClickaway={false}>
            {users.map(item => (
              <TableRow
                key={item.id}
                style={{ cursor: "pointer" }}
                selected={item.isSelected}
              >
                <TableRowColumn style={styles.columns.name}>
                  {item.first_name + " " + item.last_name}
                </TableRowColumn>
                <TableRowColumn style={styles.columns.email}>
                  {item.email}
                </TableRowColumn>
                <TableRowColumn style={styles.columns.telephone}>
                  {formatPhone(item.telephone)}
                </TableRowColumn>
                <TableRowColumn style={styles.columns.unitId}>
                  {item.unit_number}
                </TableRowColumn>
                {status === "pending" || status === "rejected" ? (
                  <TableRowColumn style={styles.columns.approve}>
                    <span
                      title="Approve"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onApproveButtonClicked(item.id);
                      }}
                    >
                      <CheckCircle />
                    </span>
                  </TableRowColumn>
                ) : null}
                {status === "pending" ? (
                  <TableRowColumn style={styles.columns.reject}>
                    <span
                      title="Reject"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onRejectButtonClicked(item.id);
                      }}
                    >
                      <Cancel />
                    </span>
                  </TableRowColumn>
                ) : null}
                {status === "approved" ? (
                  <TableRowColumn style={styles.columns.reject}>
                    <Toggle
                      defaultToggled={item.isActive}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onToggle={(e, newValue) => {
                        this.onToggle(item.id, newValue);
                        // if (!isUndefined(onChange) && isFunction(onChange)) {
                        // }
                      }}
                    />
                  </TableRowColumn>
                ) : null}
                <TableRowColumn style={styles.columns.unitId}>
                  {item.verification_status}
                </TableRowColumn>
                <TableRowColumn style={styles.columns.unitId}>
                {item.image_url?<img src={item.image_url.split(",")[0]} 
                  style={{ width:'28px', height:'28px'}} 
                  onClick={(e) => {
                      e.stopPropagation();
                      this.props.history.push({pathname: "/review", 
                      state:JSON.parse(JSON.stringify(item  )),
                      });
                }}/>:"Not Uploaded"}
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          total={this.state.total}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          numberOfRows={this.state.numberOfRows}
          updateRows={this.onPaginationFilterChange}
        />
        <Dialog title="Add Reason" actions={actions} modal={true} open={open}>
          <TextField
            name="reason"
            multiLine={true}
            rows={4}
            rowsMax={4}
            fullWidth
            value={get(this.state, "reason", "")}
            onChange={e => {
              this.onChange("reason", e.target.value);
            }}
          />
        </Dialog>
      </div>
    );
  }
}

export default withRouter(TableComponent);
