import React from "react";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import get from "lodash/get";
import set from "lodash/set";
import forOwn from "lodash/forOwn";
import isEmpty from "lodash/isEmpty";
import TextField from "material-ui/TextField";
import { grey400 } from "material-ui/styles/colors";
import PageBase from "../components/PageBase";
import { WebServiceRequest } from "../util/webRequest";
import { webServiceRoutes, UIRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";

const styles = {
  toggleDiv: {
    maxWidth: 300,
    marginTop: 40,
    marginBottom: 5
  },
  toggleLabel: {
    color: grey400,
    fontWeight: 100
  },
  buttons: {
    marginTop: 30,
    float: "right"
  },
  saveButton: {
    marginLeft: 5
  }
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      error: {},
      isLoading: false
    };
  }

  /**
   * validate change password form
   */
  validateForm() {
    set(this.state, "error", {});
    const { form, error } = this.state;
    forOwn(form, (value, key) => {
      if (typeof value == "string" && isEmpty(value)) {
        set(error, key, `${key} is required`);
      }
    });
    this.setState({ error });
    if (Object.keys(error).length) {
      return false;
    }
    return true;
  }

  /**
   * on click save button
   */
  onSaveButtonClick = () => {
    const { form } = this.state;
    console.log("yes button clicked", form);
    if (!this.validateForm()) {
      return;
    }
    let confirmPassword = get(form, "confirmPassword", "");
    let newPassword = get(form, "newPassword", "");

    if (confirmPassword !== newPassword) {
      set(
        this.state,
        "error.confirmPassword",
        "new and confirm password not match"
      );
    }

    let options = {
      method: "POST",
      url: webServiceRoutes.CHANGE_PASSWORD,
      data: form
    };
    this.setState({ isLoading: true });

    /**
     * server call for change password
     */
    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data.success) {
          let successMessage = get(result, "data.message");
          successMessage && showSnackBar(successMessage);
          this.setState({ isLoading: false });

          this.props.history.push(UIRoutes.DASHBOARD);
        }
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };
  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, `form.${field}`, value);
    set(this.state, `error.${field}`, null);
    this.setState(this.state);
  };
  /**
   * render change password page
   */
  render() {
    const { form, error, isLoading } = this.state;
    return (
      <PageBase title="Change password" navigation="Change password">
        {isLoading ? <Loader /> : null}
        <form>
          <TextField
            hintText="Old password"
            floatingLabelText="Old password"
            fullWidth={true}
            value={get(form, "oldPassword", "")}
            errorText={get(error, "oldPassword", "")}
            type="password"
            onChange={e => {
              this.onChange("oldPassword", e.target.value);
            }}
          />

          <TextField
            hintText="New Password"
            floatingLabelText="New Password"
            fullWidth={true}
            value={get(form, "newPassword", "")}
            errorText={get(error, "newPassword", "")}
            type="password"
            onChange={e => {
              this.onChange("newPassword", e.target.value);
            }}
          />

          <TextField
            hintText="Confirm Password"
            floatingLabelText="Confirm Password"
            fullWidth={true}
            value={get(form, "confirmPassword", "")}
            errorText={get(error, "confirmPassword", "")}
            type="password"
            onChange={e => {
              this.onChange("confirmPassword", e.target.value);
            }}
          />
          <div style={styles.buttons}>
            <Link to={UIRoutes.DASHBOARD}>
              <RaisedButton label="Cancel" />
            </Link>

            <RaisedButton
              label="Save"
              style={styles.saveButton}
              primary={true}
              onClick={this.onSaveButtonClick}
            />
          </div>
        </form>
      </PageBase>
    );
  }
}

export default ChangePassword;
