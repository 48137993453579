import React, { useState, useEffect } from "react";
import PageBase from "../components/PageBase";
import TextField from "material-ui/TextField";
import { Link } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";
import {
  webServiceRoutes,
  UIRoutes,
  groupVoltageList,
} from "../config/constants";
import get from "lodash/get";
import { SelectField } from "material-ui";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import { MenuItem } from "material-ui";
import find from "lodash/find";
import Toggle from "material-ui/Toggle/Toggle";
export default function TestAddProduct(props) {
  const [category, setCategory] = useState(
    get(props, "location.state.Category.categoryId", "")
  );
  const [name, setName] = useState("");
  const [isAvailable, setisAvailable] = useState(true)
  const [address, setAddress] = useState("");
  const [distributor, setDistributor] = useState("");
  const [distributorId, setDistributorId] = useState("");
  const [discount, setDiscount] = useState(0);
  const [total_available_kwh, setTotalAvailableKwh] = useState("");
  const [minimum_months, setMinimumMonths] = useState("");
  const [operation_start_month, setOperationStartMonth] = useState("");
  const [group_voltage, setGroupVoltage] = useState("");
  const [minimum_amount_kwh, setMinimumAmountKwh] = useState("");
  const [full_rate, setFullRate] = useState(0);
  const [maximum_amount_kwh, setMaximumAmountKwh] = useState("");
  const [reference_rate, setReferenceRate] = useState(0);
  const [perceived_discount, setPerceivedDiscount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [distributorList, setDistributorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [available, setAvailable] = useState("false");

  const [error, setError] = useState(false);
  const [coordinate, setCoordinate] = useState({});

  const [edit, setEdit] = useState(false);
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    if(reference_rate){
      setPerceivedDiscount(Number((full_rate*(discount/100))/reference_rate).toFixed(4))
    }
  }, [full_rate,discount,reference_rate])

  useEffect(() => {
    setIsLoading(true);

    const params = {
      category: {
        url: webServiceRoutes.ACTIVE_CATEGORY_LIST,
        method: "GET",
      },
      distributor: {
        url: webServiceRoutes.DISTRIBUTOR_LIST,
        method: "GET",
      },
    };

    WebServiceRequest.callWebService(params.category)
      .then(async (result) => {
        await setCategoryList(result.data.list_data);
        setIsLoading(false);
      })
      .catch((e) => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        setIsLoading(false);
      });

    WebServiceRequest.callWebService(params.distributor)
      .then(async (result) => {
        await setDistributorList(result.data.list_data);
        setIsLoading(false);
      })
      .catch((e) => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        setIsLoading(false);
      });

    if (props.location.state) {
      setEdit(true);
      let previousCategory = props.location.state.categoryId;
      let previousName = props.location.state.name;
      let previousAddress = props.location.state.address;
      let previousDistributor = props.location.state.distributor;
      let previousDistributorId = props.location.state.distributor_id;
      let previousDiscount = props.location.state.discount;
      let previousTotalAvailableKWH = props.location.state.total_available_kwh;
      let previousMinimumMonths = props.location.state.minimum_months;
      let previousOperationStartMonth =
        props.location.state.operation_start_month;
      let previousGroupVoltage = props.location.state.group_voltage;
      let previousMinimumAmountKwh = props.location.state.minimum_amount_kwh;
      let previousFullRate = props.location.state.full_rate;
      let previousMaximumAmountKwh = props.location.state.maximum_amount_kwh;
      let previousReferenceRate = props.location.state.reference_rate;
      let previousPerceivedDiscount = props.location.state.perceived_discount;
      let previousState = props.location.state.state;
      let previousCountry = props.location.state.country;
      let previousZipCode = props.location.state.zipCode;
      let previousAvailable = props.location.state.available;

      setCategory(previousCategory);
      setName(previousName);
      setAddress(previousAddress);
      setDistributor(previousDistributor);
      setDistributorId(previousDistributorId);
      setDiscount(previousDiscount);
      setTotalAvailableKwh(previousTotalAvailableKWH);
      setMinimumMonths(previousMinimumMonths);
      setOperationStartMonth(previousOperationStartMonth);
      setGroupVoltage(previousGroupVoltage);
      setMinimumAmountKwh(previousMinimumAmountKwh);
      setFullRate(previousFullRate);
      setMaximumAmountKwh(previousMaximumAmountKwh);
      setReferenceRate(previousReferenceRate);
      setPerceivedDiscount(previousPerceivedDiscount);
      setState(previousState);
      setCountry(previousCountry);
      setZipCode(previousZipCode);
      setAvailable(previousAvailable)
    }
  }, []);

  /*
  * on selecting Toggle
  */
 const handleToggle=(value) =>{ 
   setisAvailable(value)
    }

  /**
   * on selecting address
   *
   */
  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        const details = results.length ? results[0] : [];
        const latlng = getLatLng(results[0]);

        getLatLng(results[0]).then((res) => setCoordinate(res));
        setZipCode(
          get(
            find(
              details.address_components,
              (address) => address.types.indexOf("postal_code") > -1
            ),
            "long_name",
            null
          )
        );
        setCountry(
          get(
            find(
              details.address_components,
              (address) => address.types.indexOf("country") > -1
            ),
            "long_name",
            null
          )
        );
        setState(
          get(
            find(
              details.address_components,
              (address) =>
                address.types.indexOf("administrative_area_level_1") > -1
            ),
            "long_name",
            null
          )
        );
      })
      .catch((error) => console.error("Error", error));
  };

  const handleSelectFieldChange = (field, value) => {

    if (field === "category") setCategory(value);
    else if (field === "distributor") {
      setDistributorId(value);
      setDistributor(distributorList.filter((item) => item.distributor_id === value)[0]);
    } else setGroupVoltage(value);
  };

  let form = {
    categoryId: category,
    available,
    name,
    address,
    latitude: coordinate.lat,
    longitude: coordinate.lng,
    distributor: distributor.name,
    distributor_id: distributorId,
    discount,
    total_available_kwh,
    minimum_months,
    isAvailable,
    operation_start_month,
    group_voltage,
    minimum_amount_kwh,
    full_rate,
    maximum_amount_kwh,
    reference_rate,
    perceived_discount,
    state,
    country,
    zipCode,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      let options = {
        method: "PUT",
        url:
          webServiceRoutes.EDIT_PRODUCT + "/" + props.location.state.productId,
        data: form,
      };
      setIsLoading(true);

      WebServiceRequest.callWebService(options)
        .then((result) => {
          if (result.data) {
            showSnackBar("Product updated successfully");
            setIsLoading(false);
            props.history.push(UIRoutes.PRODUCT);
          }
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          setIsLoading(false);
        });
    } else {
      let options = {
        method: "POST",
        url: webServiceRoutes.ADD_PRODUCT,
        data: form,
      };
    
      setIsLoading(true);
      /**
       * server call for add product
       */
      WebServiceRequest.callWebService(options)
        .then((result) => {
          if (result.data) {
            showSnackBar("Product added successfully");
            setIsLoading(false);
            props.history.push(UIRoutes.PRODUCT);
          }
        })
        .catch((e) => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          setIsLoading(false);
        });
    }
  };

  const styles = {
    buttons: {
      marginTop: 30,
      float: "right",
    },
    saveButton: {
      marginLeft: 5,
    },
    inputTextField: {
      height: 72,
      width: "48%",
      marginRight: 10,
      verticalAlign: "bottom",
    },
    placesAutocomplete: {
      height: 72,
      width: "48%",
      verticalAlign: "bottom",
    },
    googlePlacesInput: {
      position: "relative",
      border: "none",
      outline: "none",
      backgroundColor: "rgba(0, 0, 0, 0)",
      cursor: "inherit",
      font: "inherit",
      opacity: 1,
      height: 68,
      boxSizing: "border-box",
      borderSpacing: 0,
      width: "98%",
      marginRight: 10,
    },
    googlePlacesWrapper: {
      fontSize: 16,
      width: "48%",
      float: "left",
      position: "relative",
      backgroundColor: "transparent",
      fontFamily: "Roboto, sans-serif",
      transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
      borderSpacing: 0,
      cursor: "auto",
      marginRight: 10,
    },
    errorTextStyle: {
      float: "left",
    },
  };

  return (
    <div>
      <PageBase
        title={edit ? "Edit Product" : "Add Product"}
        navigation={edit ? "Edit Product" : "Add Product"}
      >
        {isLoading ? <Loader /> : null}
        <form autoComplete="off" onSubmit={handleSubmit}>
          <SelectField
            value={category}
            style={{ width: "48%", marginRight: 10, float: "left" }}
            onChange={(e, next, value) => {
              handleSelectFieldChange("category", value);
            }}
            floatingLabelText="Category"
          >
            {categoryList.map((elem) => {
              return (
                <MenuItem
                  key={elem.title}
                  value={elem.categoryId}
                  primaryText={elem.title}
                />
              );
            })}
          </SelectField>
          <table width="50%" >
            <tr
              style={{
                height: 72,
                width: "90%",
                marginRight: 10,
                verticalAlign: "bottom",
                paddingBottom: 2,
              }}
            >
              <td> Available</td>
              <td>
                <Toggle
                defaultToggled={true}
                value={available}
                onToggle={(e,value)=>handleToggle(value)}
                  style={{
                    width: "48%",
                    marginRight: 10,
                    float: "left",
                    paddingTop: 6,
                  }}
                />
              </td>
            </tr>
          </table>

          <TextField
            floatingLabelText="Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />

          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
            required
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={styles.googlePlacesWrapper}>
                <input
                  {...getInputProps({
                    placeholder: "Address",
                    floatingLabelText: "Address",
                    className: "location-search-input",
                  })}
                  style={styles.googlePlacesInput}
                />
                <div
                  className="autocomplete-dropdown-container"
                  style={{
                    padding: 0,
                    position: "absolute",
                    width: "100%",
                    zIndex: 10,
                    borderBottom: "1px solid rgb(224, 224, 224)",
                    borderSpacing: 0,
                  }}
                >
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="error-text">{get(error, "address", "")}</div>
              </div>
            )}
          </PlacesAutocomplete>

          <SelectField
            value={distributorId}
            style={styles.inputTextField}
            onChange={(e, next, value) => {
              handleSelectFieldChange("distributor", value);
            }}
            floatingLabelText="Distributor"
          >
            {distributorList.map((elem) => {
              return (
                <MenuItem
                  key={elem.label}
                  value={elem.distributor_id}
                  primaryText={elem.label}
                />
              );
            })}
          </SelectField>

          <TextField
            floatingLabelText="Discount"
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
            value={discount}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />

          <TextField
            floatingLabelText="Total Available kwh"
            onChange={(e) => {
              setTotalAvailableKwh(e.target.value);
            }}
            value={total_available_kwh}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Minimum Months"
            onChange={(e) => {
              setMinimumMonths(e.target.value);
            }}
            value={minimum_months}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />

          <TextField
            floatingLabelText="Operation Start Month"
            onChange={(e) => {
              setOperationStartMonth(e.target.value);
            }}
            value={operation_start_month}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />

          <SelectField
            value={group_voltage}
            style={{ width: "48%", marginRight: 10, float: "left" }}
            onChange={(e, index, value) => {
              handleSelectFieldChange("group_voltage", value);
            }}
            floatingLabelText="Group Voltage"
          >
            {groupVoltageList.map((elem) => {
              return <MenuItem key={elem} value={elem} primaryText={elem} />;
            })}
          </SelectField>

          <TextField
            floatingLabelText="Minimum Amount kWh"
            onChange={(e) => {
              setMinimumAmountKwh(e.target.value);
            }}
            value={minimum_amount_kwh}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Full rate"
            onChange={(e) => {
              setFullRate(e.target.value);
            }}
            value={full_rate}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Maximum Amount kWh"
            onChange={(e) => {
              setMaximumAmountKwh(e.target.value);
            }}
            value={maximum_amount_kwh}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Reference rate"
            onChange={(e) => {
              setReferenceRate(e.target.value);
            }}
            value={reference_rate}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <TextField
            floatingLabelText="Perceived Discount"
            value={perceived_discount}
            style={styles.inputTextField}
            errorStyle={styles.errorTextStyle}
            required
          />
          <br />
          <div style={styles.buttons}>
            <Link to="/products">
              <RaisedButton label="Cancel" />
            </Link>

            <RaisedButton
              type="submit"
              label="Save"
              style={styles.saveButton}
              primary={true}
            />
          </div>
        </form>
      </PageBase>
    </div>
  );
}
