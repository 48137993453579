import React from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";
import get from "lodash/get";
import set from "lodash/set";
import { webServiceRoutes } from "../config/constants";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "./Loader";

const styles = {
  columns: {
    width_100: {
      width: "100px"
    },
    width_80: {
      width: 80
    },
    width_40: {
      width: "40px"
    },
    width_150: {
      width: 150
    }
  },
  importStyle: {
    float: "right",
    marginTop: "-3px",
    marginRight: "5px",
    borderRadius: 3
  },
  importButton: {
    padding: "6px 9px",
    border: 0,
    borderRadius: 3,
    color: "#ffffff",
    backgroundColor: "rgb(41, 103, 134)",
    fontSize: "14px"
    // height: "24px"
    // height: "unset"
  }
};

class ImportLogsData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.getUnitsData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.importLog !== this.props.importLog) {
      this.getUnitsData();
    }
  }

  /**
   * get units import data
   */
  getUnitsData = () => {
    const id = this.props.match.params.id;
    const params = {
      url: `${webServiceRoutes.IMPORT_LOGS}?id=${id}`,
      method: "GET"
    };
    this.setState({ isLoading: true });

    /**
     * server call for units import data
     */
    WebServiceRequest.callWebService(params)
      .then(result => {
        const data = result.data;
        set(this.state, "units", data);
        set(this.state, "isLoading", false);
        this.setState(this.state);
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * render import unit table
   */
  render() {
    const { units, isLoading } = this.state;
    const isEdgeBrowser = navigator.appVersion.indexOf("Edge") < 0;
    return (
      <div>
        {isLoading ? <Loader /> : null}
        <React.Fragment>
          <Table
            selectable={false}
            displayRowCheckbox={false}
            style={{ width: "100%" }}
            bodyStyle={
              isEdgeBrowser
                ? { overflow: "unset" }
                : { overflowX: "unset", overflowY: "unset" }
            }
          >
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={styles.columns.width_150}>
                  Date
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_100}>
                  Total count
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_100}>
                  Success count
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_100}>
                  Failed count
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_100}>
                  Status
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {units.map(item => (
                <TableRow key={item.id}>
                  <TableRowColumn style={styles.columns.width_150}>
                    {new Date(item.createdAt).toLocaleString("en-US")}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_100}>
                    {item.totalCount}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_100}>
                    {item.successCount}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_100}>
                    {item.failedCount}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_100}>
                    {item.status}
                  </TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(ImportLogsData);
