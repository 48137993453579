import React from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";
import { Link } from "react-router-dom";
import SelectField from "material-ui/SelectField";
import SearchBar from "material-ui-search-bar";
import MenuItem from "material-ui/MenuItem";
import { grey400 } from "material-ui/styles/colors";
import Add from "material-ui/svg-icons/content/add";
import Clear from "material-ui/svg-icons/content/clear";
import Done from "material-ui/svg-icons/action/done";
import Delete from "material-ui/svg-icons/action/delete";
import Edit from "material-ui/svg-icons/image/edit";
import TextField from "material-ui/TextField";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import isNumber from "lodash/isNumber";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  webServiceRoutes,
  distributorList,
  phaseList,
  groupVoltageList,
  allowedKeys
} from "../config/constants";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import { Loader } from "../components/Loader";

const styles = {
  toggleDiv: {
    maxWidth: 300,
    marginTop: 40,
    marginBottom: 5
  },
  toggleLabel: {
    color: grey400,
    fontWeight: 100
  },
  buttons: {
    marginTop: 30,
    float: "right"
  },
  saveButton: {
    marginLeft: 5
  },
  googlePlacesInput: {
    padding: 0,
    position: "relative",
    border: "none",
    outline: "none",
    backgroundColor: "rgba(0, 0, 0, 0)",
    cursor: "inherit",
    font: "inherit",
    opacity: 1,
    height: 55,
    boxSizing: "border-box",
    width: "100%",
    marginRight: 10
  },
  googlePlacesWrapper: {
    fontSize: 16,
    width: "48%",
    height: 47,
    float: "left",
    position: "relative",
    backgroundColor: "transparent",
    fontFamily: "Roboto, sans-serif",
    transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    cursor: "auto",
    borderBottom: "1px solid rgb(224, 224, 224)",
    marginRight: 10,
    marginLeft: 20,
    zIndex: 100
  },
  inputTextField: {
    height: 55,
    width: "48%"
  },
  inputTextFieldPhase: {
    height: 55,
    width: "48%",
    float: "right",
    right: 7
  },
  inputFieldUnit: {
    marginLeft: 20,
    height: 55,
    width: "48%",
    marginRight: 10,
    zIndex: 1
  },
  selectField: {
    marginLeft: 20,
    width: "48%",
    marginRight: 10
  },
  columns: {
    width_100: {
      width: "100px"
    },
    width_80: {
      width: 80
    },
    width_40: {
      width: "40px"
    },
    width_150: {
      width: 150
    }
  },
  importStyle: {
    float: "right",
    marginTop: "-3px",
    marginRight: "5px",
    borderRadius: 3
  },
  importButton: {
    cursor: "pointer",
    padding: "6px 9px",
    border: 0,
    borderRadius: 3,
    color: "#ffffff",
    backgroundColor: "rgb(41, 103, 134)",
    fontSize: "14px"
    // height: "24px"
    // height: "unset"
  },
  searchBar: {
    margin: "0 auto",
    marginTop: "-8px",
    marginRight: "5px",
    height: 42,
    maxWidth: 400,
    float: "right",
    boxShadow: "none",
    border: "1px solid rgb(224, 224, 224)"
  }
};

class UnitData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      distributors:[],
      distributorId:"",
      isShowUnitForm: false,
      isEditUnit: false,
      unitForm: {
        unit_number: "",
        distributor: "",
        client: "",
        address: "",
        latitude: "",
        longitude: "",
        phase: "",
        power_plant: "",
        group_voltage: "",
        cnpj: "",
        nickName: "",
        contract: "",
        zipCode: "",
        state: "",
        country: ""
      },
      isLoading: false,
      searchText: ""
    };
  }

  componentDidMount() {
    this.getUnitsData();
    this.getDistributors();
  }

  /**
   * get user data
   */
  getUnitsData = () => {
    const id = this.props.match.params.id;
    const params = {
      url: webServiceRoutes.USER_UNITS + id,
      method: "GET"
    };
    this.setState({ isLoading: true });

    WebServiceRequest.callWebService(params)
      .then(result => {
        const data = result.data;
        set(this.state, "units", data);
        set(this.state, "isLoading", false);
        this.setState(this.state);
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };
  /**
   * get distributors list
   */
  getDistributors = () => {
    this.setState({ isLoading: true });
    const params = {
      url: webServiceRoutes.DISTRIBUTOR_LIST,
      method: "GET",
  };

  WebServiceRequest.callWebService(params)
    .then(async (result) => {
        await set(this.state, "distributors", result.data.list_data);
        set(this.state, "isLoading", false);
        this.setState(this.state);
    })
    .catch((e) => {
      let errorMessage = get(e, "response.data.message");
      errorMessage && showSnackBar(errorMessage);
      this.setState({ isLoading: false });
    });

  };
  /**
   * validate unit form
   */
  validateForm = () => {
    set(this.state, "unitFormError", {});
    const { unitForm, unitFormError } = this.state;
    forOwn(unitForm, (value, key) => {
      if (
        typeof value == "string" &&
        isEmpty(value) &&
        key !== "power_plant" &&
        key !== "nickName" &&
        key !== "contract" &&
        key !== "zipCode" &&
        key !== "state" &&
        key !== "country"
      ) {
        set(unitFormError, key, `${key} is required`);
      }
      if (key === "contract" && this.state.unitForm.power_plant && !value) {
        set(unitFormError, "contract", "contract is required");
      }
      if (
        (key === "latitude" && !isNumber(value)) ||
        (key === "longitude" && !isNumber(value))
      ) {
        set(unitFormError, `address`, "Please select address from the list");
      }
    });
    this.setState({ unitFormError });
    if (Object.keys(unitFormError).length) {
      return false;
    }
    return true;
  };

  /**
   * on click save unit data
   */
  onSaveUnitData = () => {
    if (!this.validateForm()) {
      return;
    }
    this.props.showButtons(true);
    const { unitForm, isShowUnitForm, isEditUnit } = this.state;
    if (isShowUnitForm) {
      this.addNewUnit(unitForm);
    } else if (isEditUnit) {
      this.updateUnit(unitForm);
    }
  };

  /**
   * on click add new unit
   * @param {object} unitForm
   */
  addNewUnit(unitForm) {
    const id = this.props.match.params.id;
    set(unitForm, "user_id", id);
    let options = {
      method: "POST",
      url: webServiceRoutes.ADD_UNIT,
      data: unitForm
    };
    this.setState({ isLoading: true });

    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data) {
          let units = get(this.state, "units", []);
          units.push(result.data);
          this.setState({ units, isShowUnitForm: false, isLoading: false });
          this.props.showButtons(true);
          showSnackBar("Data saved successfully");
          // this.props.history.push(UIRoutes.DASHBOARD);
        }
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  }

  /**
   * get user unit data
   * @param {object} unitForm
   */
  updateUnit(unitForm) {
    const { id } = unitForm;
    let cloneObj = JSON.parse(JSON.stringify(unitForm));
    delete cloneObj.id;
    let options = {
      method: "PUT",
      url: webServiceRoutes.UPDATE_UNIT + id,
      data: cloneObj
    };
    this.setState({ isLoading: true });
    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data) {
          let units = get(this.state, "units", []);
          // units.push(result.data);
          this.setState({
            isShowUnitForm: false,
            isLoading: false,
            isEditUnit: false
          });
          this.getUnitsData();
          this.props.showButtons(true);
          showSnackBar("Data saved successfully");
          // this.props.history.push(UIRoutes.DASHBOARD);
        }
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  }
  handleDistributorSelectField = (value) => {
    set(this.state.unitForm, "distributor", this.state.distributors.filter((item) => item.name === value)[0].name);
    this.setState(this.state);
  };
  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, `unitForm.${field}`, value);
    set(this.state, `unitFormError.${field}`, null);
    this.setState(this.state);
  };

  /**
   * on changing value of address input fields
   * @param {string} address
   */
  handleChange = address => {
    set(this.state, "unitForm.address", address);
    set(this.state, `unitFormError.address`, null);
    this.setState(this.state);
  };

  /**
   * on selecting address
   * @param {object} address
   */
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const details = results.length ? results[0] : [];
        set(
          this.state,
          "unitForm.zipCode",
          get(
            find(
              details.address_components,
              address => address.types.indexOf("postal_code") > -1
            ),
            "long_name",
            null
          )
        );
        set(
          this.state,
          "unitForm.country",
          get(
            find(
              details.address_components,
              address => address.types.indexOf("country") > -1
            ),
            "long_name",
            null
          )
        );
        set(
          this.state,
          "unitForm.state",
          get(
            find(
              details.address_components,
              address =>
                address.types.indexOf("administrative_area_level_1") > -1
            ),
            "long_name",
            null
          )
        );
        return getLatLng(results[0]);
      })
      .then(latLng => {
        set(this.state, "unitForm.address", address);
        set(this.state, `unitForm.latitude`, latLng.lat);
        set(this.state, `unitForm.longitude`, latLng.lng);
        this.setState(this.state);
      })
      .catch(error => console.error("Error", error));
  };

  /**
   * on click delete button
   */
  onDeleteButtonClick = id => {
    const params = {
      url: webServiceRoutes.DELETE_UNIT + id,
      method: "delete",
      data: {}
    };
    this.setState({ isLoading: true });

    WebServiceRequest.callWebService(params)
      .then(result => {
        let successMessage = get(result, "data.message");
        successMessage && showSnackBar(successMessage);
        let units = get(this.state, "units", []);
        let index = findIndex(units, item => {
          return item.id === id;
        });
        if (index > -1) units.splice(index, 1);
        this.setState({ units, isLoading: false });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * on click edit button
   * @param {object} item
   */
  onEditButtonClick(item) {
    set(this.state, "unitForm", item);
    set(this.state, "isEditUnit", true);
    this.setState(this.state);
  }

  /**
   * on selecting input fields
   * @param {string} field
   * @param {string} value
   */
  handleSelectFieldChange = (field, value) => {
    set(this.state, `unitForm.${field}`, value);
    set(this.state, `unitFormError.${field}`, null);
    this.setState(this.state);
  };

  /**
   * get search units
   */
  getSearchUnits = () => {
    const id = this.props.match.params.id;
    const params = {
      url: webServiceRoutes.SEARCH_UNITS,
      method: "GET",
      params: {
        id,
        q: this.state.searchText
      }
    };
    this.setState({ isLoading: true }, () => {
      WebServiceRequest.callWebService(params)
        .then(result => {
          const data = result.data.units;
          set(this.state, "units", data);
          set(this.state, "isLoading", false);
          this.setState(this.state);
        })
        .catch(e => {
          let errorMessage = get(e, "response.data.message");
          errorMessage && showSnackBar(errorMessage);
          this.setState({ isLoading: false });
        });
    });
  };

  /**
   * render unitData component
   */
  render() {
    const {
      units,
      isShowUnitForm,
      unitForm,
      unitFormError,
      isLoading,
      isEditUnit
    } = this.state;
    const isEdgeBrowser = navigator.appVersion.indexOf("Edge") < 0;
    return (
      <div>
        {isLoading ? <Loader /> : null}
        <div className="formdesign form-sepecial">
          <div className="Accordian-section clearfix cus-clear-left">
            <div className="accordian-head">
              <div>
                {isShowUnitForm ? (
                  <span>Add unit</span>
                ) : isEditUnit ? (
                  <span>Edit unit</span>
                ) : (
                  <span>Unit Data</span>
                )}
                {isShowUnitForm || isEditUnit ? (
                  <span style={{ float: "right", cursor: "pointer" }}>
                    <span
                      onClick={() => {
                        this.onSaveUnitData();
                      }}
                    >
                      <Done />
                    </span>
                    <span
                      onClick={() => {
                        this.setState({
                          isShowUnitForm: false,
                          isEditUnit: false
                        });
                        this.props.showButtons(true);
                      }}
                    >
                      <Clear />
                    </span>
                  </span>
                ) : (
                  <span
                    style={{ float: "right", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ isShowUnitForm: true, unitForm: {} });
                      this.props.showButtons(false);
                    }}
                  >
                    <Add />
                  </span>
                )}
                {this.props.isShowImportButton &&
                !isShowUnitForm &&
                !isEditUnit ? (
                  <React.Fragment>
                    <Link to={`/importUnits/${this.props.userId}`}>
                      <span style={styles.importStyle}>
                        <button type="button" style={styles.importButton}>
                          Import units
                        </button>
                      </span>
                    </Link>
                    <span className="search-wrapper" style={{ height: "35px" }}>
                      <SearchBar
                        onChange={searchText => {
                          this.setState({ searchText });
                        }}
                        onRequestSearch={() => {
                          this.getSearchUnits();
                          // e.preventDefault();
                        }}
                        style={styles.searchBar}
                      />
                    </span>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isShowUnitForm || isEditUnit ? (
          <div>
            <TextField
              hintText="Unit"
              value={get(unitForm, "unit_number", "")}
              errorText={get(unitFormError, "unit_number", "")}
              errorStyle={{
                float: "left"
              }}
              onChange={e => {
                this.onChange("unit_number", e.target.value);
              }}
              style={styles.inputFieldUnit}
              onKeyDown={e => {
                if (allowedKeys.indexOf(e.which) === -1) {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }
              }}
            />
            <TextField
              hintText="Nick name"
              value={get(unitForm, "nickName", "")}
              // errorText={get(unitFormError, "nickName", "")}
              // errorStyle={{
              //   float: "left"
              // }}
              onChange={e => {
                this.onChange("nickName", e.target.value);
              }}
              style={styles.inputTextField}
            />
            <TextField
              hintText="Client"
              value={get(unitForm, "client", "")}
              errorText={get(unitFormError, "client", "")}
              errorStyle={{
                float: "left"
              }}
              onChange={e => {
                this.onChange("client", e.target.value);
              }}
              style={styles.inputFieldUnit}
            />
            <TextField
              hintText="CNPJ/CPF"
              value={get(unitForm, "cnpj", "")}
              errorText={get(unitFormError, "cnpj", "")}
              onChange={e => {
                this.onChange("cnpj", e.target.value);
              }}
              style={{
                ...styles.inputFieldUnit,
                marginRight: 0,
                marginLeft: 0
              }}
              onKeyDown={e => {
                if (allowedKeys.indexOf(e.which) === -1) {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }
              }}
            />
            {/* <TextField
              hintText="Distributor"
              value={get(unitForm, "distributor", "")}
              errorText={get(unitFormError, "distributor", "")}
              errorStyle={{
                float: "left"
              }}
              onChange={e => {
                this.onChange("distributor", e.target.value);
              }}
              style={styles.inputFieldUnit}
            /> */}
            <SelectField
            value={get(unitForm, "distributor", "")}
            style={{ ...styles.inputFieldUnit, height: 72 }}
            onChange={(e, next, value) => {
              this.handleDistributorSelectField(value);
            }}
            floatingLabelText="Distributor"
          >
            {this.state.distributors.map((elem) => {
              return (
                <MenuItem
                  key={elem.label}
                  value={elem.name}
                  primaryText={elem.label}
                />
              );
            })}
          </SelectField>
            <SelectField
              value={get(unitForm, "phase", "")}
              style={{ width: "48%" }}
              onChange={(e, index, value) => {
                this.handleSelectFieldChange("phase", value);
              }}
              floatingLabelText="Phase"
              errorText={get(unitFormError, "phase", "")}
            >
              {phaseList.map(elem => {
                return <MenuItem key={elem} value={elem} primaryText={elem} />;
              })}
            </SelectField>
            <SelectField
              value={get(unitForm, "group_voltage", "")}
              style={{ width: "48%", marginRight: 10, marginLeft: 20 }}
              onChange={(e, index, value) => {
                this.handleSelectFieldChange("group_voltage", value);
              }}
              floatingLabelText="Group Voltage"
              errorText={get(unitFormError, "group_voltage", "")}
            >
              {groupVoltageList.map(elem => {
                return <MenuItem key={elem} value={elem} primaryText={elem} />;
              })}
            </SelectField>
            <TextField
              hintText="Power plant"
              value={get(unitForm, "power_plant", "")}
              errorText={get(unitFormError, "power_plant", "")}
              errorStyle={{
                float: "left"
              }}
              onChange={e => {
                this.onChange("power_plant", e.target.value);
              }}
              style={{ ...styles.inputTextField, bottom: 20 }}
            />
            <PlacesAutocomplete
              value={get(unitForm, "address", "")}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div style={styles.googlePlacesWrapper}>
                  <input
                    {...getInputProps({
                      placeholder: "Address",
                      className: "location-search-input"
                    })}
                    style={styles.googlePlacesInput}
                  />
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="error-text">
                    {get(unitFormError, "address", "")}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <TextField
              hintText="Contract - R$/MWh"
              type="number"
              value={get(unitForm, "contract", "")}
              errorText={get(unitFormError, "contract", "")}
              errorStyle={{
                float: "left"
              }}
              onChange={e => {
                this.onChange("contract", e.target.value);
              }}
              style={styles.inputTextField}
            />
          </div>
        ) : (
          <Table
            selectable={false}
            displayRowCheckbox={false}
            style={{ width: "100%" }}
            bodyStyle={
              isEdgeBrowser
                ? { overflow: "unset" }
                : { overflowX: "unset", overflowY: "unset" }
            }
          >
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Unit number
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Nick name
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  CNPJ/CPF
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_150}>
                  Address
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Client
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Distributor
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Power Plant
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Contract
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Group Voltage
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Phase
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Edit
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.columns.width_80}>
                  Delete
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {units.map(item => (
                <TableRow key={item.id}>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.unit_number}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.nickName}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.cnpj}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_150}>
                    {item.address}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.client}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.distributor}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.power_plant}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.contract}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.group_voltage}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    {item.phase}
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={e => {
                        this.onEditButtonClick(
                          JSON.parse(JSON.stringify(item))
                        );
                        this.props.showButtons(false);
                      }}
                    >
                      <Edit />
                    </span>
                  </TableRowColumn>
                  <TableRowColumn style={styles.columns.width_80}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={e => {
                        this.onDeleteButtonClick(item.id);
                      }}
                    >
                      <Delete />
                    </span>
                  </TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

export default withRouter(UnitData);
