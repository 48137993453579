import React from "react";
import { Link, withRouter } from "react-router-dom";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import Menu from "material-ui/svg-icons/navigation/menu";
import { white } from "material-ui/styles/colors";
import { WebServiceRequest } from "../util/webRequest";
import { webServiceRoutes, UIRoutes } from "../config/constants";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import get from "lodash/get";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  /**
   * sign out admin
   */
  signout = () => {
    const params = {
      url: webServiceRoutes.SIGNOUT,
      data: {},
      method: "GET"
    };
    /**
     * server call for admin sign out
     */
    WebServiceRequest.callWebService(params)
      .then(result => {
        this.props.history.push(UIRoutes.LOGIN);
      })
      .catch(e => {
        console.log(e);
        showSnackBar("Interal server error");
      });
  };

  /**
   * render header
   */
  render() {
    const { styles, handleChangeRequestNavDrawer } = this.props;

    const style = {
      appBar: {
        position: "fixed",
        top: 0,
        overflow: "hidden",
        maxHeight: 57
      },
      menuButton: {
        marginLeft: 10
      },
      iconsRightContainer: {
        marginLeft: 20
      }
    };
    let myProfileUrl = UIRoutes.USER_DETAIL + get(this.props, "user.id", "");
    return (
      <div>
        <AppBar
          style={{ ...styles, ...style.appBar }}
          iconElementLeft={
            <IconButton
              style={style.menuButton}
              onClick={handleChangeRequestNavDrawer}
            >
              <Menu color={white} />
            </IconButton>
          }
          iconElementRight={
            <div style={style.iconsRightContainer}>
              <IconMenu
                color={white}
                iconButtonElement={
                  <IconButton>
                    <MoreVertIcon color={white} />
                  </IconButton>
                }
                targetOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <MenuItem
                  primaryText="My Profile"
                  containerElement={<Link to={myProfileUrl} />}
                />
                <MenuItem
                  primaryText="Change password"
                  containerElement={<Link to={UIRoutes.CHANGE_PASSWORD} />}
                />
                <MenuItem primaryText="Sign out" onClick={this.signout} />
              </IconMenu>
            </div>
          }
        />
      </div>
    );
  }
}

export default withRouter(Header);
