import React from "react";
import { Link, withRouter } from "react-router-dom";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { grey400 } from "material-ui/styles/colors";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import PageBase from "../components/PageBase";
import { webServiceRoutes, UIRoutes, allowedKeys } from "../config/constants";
import { WebServiceRequest } from "../util/webRequest";
import { showSnackBar } from "../util/components/snackbar/Snackbar";
import UnitData from "../components/UnitData";
import { Loader } from "../components/Loader";
import { formatPhone } from "../util/methods";

const styles = {
  toggleDiv: {
    maxWidth: 300,
    marginTop: 40,
    marginBottom: 5
  },
  toggleLabel: {
    color: grey400,
    fontWeight: 100
  },
  buttons: {
    marginTop: 30,
    float: "right"
  },
  saveButton: {
    marginLeft: 5
  },
  googlePlacesInput: {
    padding: 0,
    position: "relative",
    border: "none",
    outline: "none",
    backgroundColor: "rgba(0, 0, 0, 0)",
    cursor: "inherit",
    font: "inherit",
    opacity: 1,
    height: 55,
    boxSizing: "border-box",
    width: "100%",
    marginRight: 10
  },
  googlePlacesWrapper: {
    fontSize: 16,
    width: "48%",
    height: 47,
    float: "left",
    position: "relative",
    backgroundColor: "transparent",
    fontFamily: "Roboto, sans-serif",
    transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    cursor: "auto",
    borderBottom: "1px solid rgb(224, 224, 224)"
  },
  inputTextField: {
    height: 55,
    width: "48%",
    marginRight: 10
  },
  cnpjTextField: {
    marginRight: 0,
    marginLeft: 10,
    height: 55,
    width: "48%"
  },
  columns: {
    width_100: {
      width: "100px"
    }
  }
};

class DetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        telephone: ""
      },
      units: [],
      error: {},
      isShowButtons: true,
      isLoading: false
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  /**
   * get user data
   */
  getUserData = () => {
    const id = this.props.match.params.id;
    const params = {
      url: webServiceRoutes.USER_DETAIL + id,
      method: "GET"
    };
    this.setState({ isLoading: true });
    /**
     * server call for user data
     */
    WebServiceRequest.callWebService(params)
      .then(result => {
        const data = result.data;
        set(this.state, "form", data);
        set(this.state, "isLoading", false);
        this.setState(this.state);
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * validate user data form
   */
  validateForm() {
    set(this.state, "error", {});
    const { form, error } = this.state;
    forOwn(form, (value, key) => {
      if (typeof value == "string" && isEmpty(value)) {
        set(error, key, `${key} is required`);
      }
      if (key == "telephone") {
        let telephone = value.replace(/\D/g, "");
        if (telephone.length < 11) {
          set(error, `telephone`, "Telphone not valid");
        }
      }
    });
    this.setState({ error });
    if (Object.keys(error).length) {
      return false;
    }
    return true;
  }

  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, `form.${field}`, value);
    set(this.state, `error.${field}`, null);
    if (field == "telephone") {
      let telephone = value.replace(/\D/g, "");
      if (telephone.length < 11) {
        set(this.state, `error.telephone`, "Telphone not valid");
      }
    }
    this.setState(this.state);
  };

  /**
   * on click save button
   */
  onSaveButtonClick = () => {
    if (!this.validateForm()) {
      return;
    }
    const { form } = this.state;
    form.telephone = form.telephone.replace(/\D/g, "");
    let options = {
      method: "PUT",
      url: webServiceRoutes.UPDATE_USER + this.props.match.params.id,
      data: form
    };
    this.setState({ isLoading: true });
    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data) {
          showSnackBar("Data saved successfully");
          this.props.history.push(UIRoutes.DASHBOARD);
        }
        this.setState({ isLoading: false });
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
        this.setState({ isLoading: false });
      });
  };

  /**
   * on changing value of address input fields
   * @param {string} address
   */
  handleChange = address => {
    set(this.state, "form.address", address);
    set(this.state, `error.address`, null);
    this.setState(this.state);
  };

  /**
   * on selecting address
   * @param {object} address
   */
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        set(this.state, "form.address", address);
        set(this.state, `form.latitude`, latLng.lat);
        set(this.state, `form.longitude`, latLng.lng);
        this.setState(this.state);
      })
      .catch(error => console.error("Error", error));
  };
  /**
   * for showing action buttons
   */
  showButtons = isShowButtons => {
    this.setState({ isShowButtons });
  };
  /**
   * render Add user page
   */
  render() {
    const { form, error, isShowButtons, isLoading } = this.state;
    const id = this.props.match.params.id;
    const loggedInUserId = get(this.props, "user.id", "");
    return (
      <PageBase title="User Detail" navigation="User Detail">
        {isLoading ? <Loader /> : null}
        <form>
          <TextField
            hintText="First Name"
            value={get(form, "first_name", "")}
            errorText={get(error, "first_name", "")}
            onChange={e => {
              this.onChange("first_name", e.target.value);
            }}
            style={styles.inputTextField}
          />

          <TextField
            hintText="Last Name"
            value={get(form, "last_name", "")}
            errorText={get(error, "last_name", "")}
            onChange={e => {
              this.onChange("last_name", e.target.value);
            }}
            style={styles.inputTextField}
          />

          <TextField
            hintText="Email"
            value={get(form, "email", "")}
            errorText={get(error, "email", "")}
            onChange={e => {
              this.onChange("email", e.target.value);
            }}
            disabled
            style={styles.inputTextField}
          />

          <TextField
            hintText="Telephone"
            value={formatPhone(get(form, "telephone", ""))}
            errorText={get(error, "telephone", "")}
            onChange={e => {
              this.onChange("telephone", e.target.value);
            }}
            style={styles.inputTextField}
            onKeyDown={e => {
              if (allowedKeys.indexOf(e.which) < 0) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
          {loggedInUserId !== id ? (
            <UnitData
              id={id}
              showButtons={this.showButtons}
              isShowImportButton={true}
              userId={id}
            />
          ) : null}

          {isShowButtons ? (
            <div style={styles.buttons}>
              <Link to={UIRoutes.DASHBOARD}>
                <RaisedButton label="Cancel" />
              </Link>

              <RaisedButton
                label="Save"
                style={styles.saveButton}
                primary={true}
                onClick={this.onSaveButtonClick}
              />
            </div>
          ) : null}
        </form>
      </PageBase>
    );
  }
}

export default withRouter(DetailPage);
