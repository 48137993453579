import React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Paper from "material-ui/Paper";
import get from "lodash/get";
import set from "lodash/set";
import forOwn from "lodash/forOwn";
import isEmpty from "lodash/isEmpty";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { grey500, white } from "material-ui/styles/colors";
import TextField from "material-ui/TextField";
import { Link } from "react-router-dom";
import ThemeDefault from "../../theme-default";
import { WebServiceRequest } from "../../util/webRequest";
import {
  webServiceRoutes,
  UIRoutes,
  errorFieldMapping
} from "../../config/constants";
import { isLoggedIn, VALIDATE_EMAIL } from "../../util/methods";
import Snackbar, {
  showSnackBar
} from "../../util/components/snackbar/Snackbar";
import { Loader } from "../Loader";

const styles = {
  loginContainer: {
    minWidth: 320,
    maxWidth: 400,
    height: "auto",
    position: "absolute",
    top: "20%",
    left: 0,
    right: 0,
    margin: "auto"
  },
  paper: {
    padding: "50px 26px",
    overflow: "auto"
  },
  buttonsDiv: {
    textAlign: "left",
    padding: "10px",
    float: "left",
    paddingLeft: "0px",
    marginLeft: "-12px"
  },
  flatButton: {
    color: grey500,
    height: "22px",
    lineHeight: "20px",
    fontSize: "9px",
    marginBottom: "28px",
    marginTop: "20px",
    float: "right"
  },
  loginBtn: {
    float: "right"
  },
  btn: {
    background: "#4f81e9",
    color: white,
    padding: 7,
    borderRadius: 2,
    margin: 2,
    fontSize: 13
  },
  btnSpan: {
    marginLeft: 5
  },
  labelStyle: {
    fontSize: "12px",
    paddingLeft: "0px"
  },
  logoImageStyle: {
    maxWidth: 411,
    maxHeight: 55
  },
  h2: {
    margin: 0,
    color: "#333f48",
    fontSize: "24px",
    fontWeight: 400,
    paddingBottom: "52px",
    textAlign: "center"
  }
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      error: {},
      isLoading: false
    };
  }

  componentWillMount() {
    this.setState({ isLoading: true });
    /**
     * check is admin loggedIn
     */
    isLoggedIn()
      .then(user => {
        this.setState({ isLoading: false });
        if (user.role === "admin") {
          this.props.history.push(UIRoutes.DASHBOARD);
        } else {
          showSnackBar("You don't have permission");
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        console.log(e);
        this.props.history.push("/login");
      });
  }

  /**
   * validate login form data
   */
  validateForm() {
    set(this.state, "error", {});
    const { form, error } = this.state;
    forOwn(form, (value, key) => {
      if (typeof value == "string" && isEmpty(value)) {
        set(error, key, `${errorFieldMapping[key]} is required`);
      }
      if (key === "email") {
        const isValidEmail = VALIDATE_EMAIL(value);
        if (!isValidEmail) {
          set(this.state, `error.${key}`, "Invalid email");
        }
      }
    });
    this.setState({ error });
    if (Object.keys(error).length) {
      return false;
    }
    return true;
  }

  /**
   * on changing value of input fields
   * @param {string} field
   * @param {string} value
   */
  onChange = (field, value) => {
    set(this.state, `form.${field}`, value);
    set(this.state, `error.${field}`, null);
    this.setState(this.state);
  };

  /**
   * on click login button
   */
  onLoginButtonClick = () => {
    if (!this.validateForm()) {
      return;
    }
    const { form } = this.state;
    let options = {
      method: "POST",
      url: webServiceRoutes.LOGIN,
      data: form
    };

    this.setState({ isLoading: true });

    /**
     * server call for admin authentication
     */
    WebServiceRequest.callWebService(options)
      .then(result => {
        if (result.data.success) {
          set(this.state, "user", get(result, "data.user", {}));
          const user = get(result, "data.user", {});
          if (user.role === "admin") {
            this.props.history.push(UIRoutes.DASHBOARD);
          } else {
            showSnackBar("You don't have permission");
          }
        } else {
          get(result, "data.userError", false) &&
            set(this.state, "error.email", get(result, "data.message", null));
          get(result, "data.passwordError", false) &&
            set(
              this.state,
              "error.password",
              get(result, "data.message", null)
            );
        }
        set(this.state, "isLoading", false);
        this.setState(this.state);
      })
      .catch(e => {
        let errorMessage = get(e, "response.data.message");
        errorMessage && showSnackBar(errorMessage);
      });
  };

  /**
   * render login page
   */
  render() {
    const logo = "../../../assets/logo.png";
    const { form, error, isLoading } = this.state;
    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        {isLoading ? <Loader /> : null}
        <div className="header">
          <div className="container">
            <div className="header-wraper">
              <div className="headerLogo">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo-image"
                  style={styles.logoImageStyle}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={styles.loginContainer}>
            <Paper style={styles.paper}>
              <h2 style={styles.h2}>Log in</h2>
              <form>
                <TextField
                  hintText="E-mail"
                  floatingLabelText="E-mail"
                  fullWidth={true}
                  value={get(form, "email", "")}
                  onChange={e => {
                    this.onChange("email", e.target.value);
                  }}
                  errorText={get(error, "email", "")}
                />
                <TextField
                  hintText="Password"
                  floatingLabelText="Password"
                  fullWidth={true}
                  type="password"
                  value={get(form, "password", "")}
                  onChange={e => {
                    this.onChange("password", e.target.value);
                  }}
                  errorText={get(error, "password", "")}
                />

                <div>
                  <Link to={UIRoutes.FORGOT_PASSWORD}>
                    <FlatButton
                      label="Forgot Password?"
                      style={styles.flatButton}
                      labelStyle={styles.labelStyle}
                    />
                  </Link>

                  <RaisedButton
                    label="Login"
                    primary={true}
                    fullWidth
                    onClick={this.onLoginButtonClick}
                  />
                </div>
              </form>
            </Paper>
          </div>
        </div>
        <Snackbar />
      </MuiThemeProvider>
    );
  }
}

export default Login;
