import React from "react";
import { Link } from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { pink500 } from "material-ui/styles/colors";

import PageBase from "../components/PageBase";
import TableComponent from "../components/TableComponent";

const styles = {
  floatingActionButton: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed"
  }
};

class ApprovedUsers extends React.Component {
  /**
   * render ApprovedUsers page
   */
  render() {
    return (
      <div style={{ position: "relative" }}>
        <PageBase title="Approved Users" navigation="Approved Users">
          <div>
            <Link to="/addUser">
              <FloatingActionButton
                style={styles.floatingActionButton}
                backgroundColor={pink500}
              >
                <ContentAdd />
              </FloatingActionButton>
            </Link>
          </div>
          <TableComponent status="approved" />
        </PageBase>
      </div>
    );
  }
}

export default ApprovedUsers;
