import React from "react";
import { webServiceRoutes } from "../config/constants";
import Table from "./Table";
import { Link } from "react-router-dom";
import PageBase from "./PageBase";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { pink500 } from "material-ui/styles/colors";
export default function orders(props) {
  const columns = [
    {
      columnAlias: "User Email",
      cell: (row) => {
        return row.user_email;
      },
      onClick: (data) => {
        InfoForm(data);
      },
    },
    {
      columnAlias: "First Name",
      cell: (row) => {
        return row.first_name;
      },
      onClick: (data) => {
        InfoForm(data);
      },
    },
    {
      columnAlias: "Last Name",
      cell: (row) => {
        return row.last_name;
      },
      onClick: (data) => {
        InfoForm(data);
      },
    },
    {
      columnAlias: "Unit Number",
      cell: (row) => {
        return row.unit_number;
      },
      onClick: (data) => {
        InfoForm(data);
      },
    },
    {
      columnAlias: "Product Name",
      cell: (row) => {
        return row.name;
      },
      onClick: (data) => {
        InfoForm(data);
      },
    },
    {
      columnAlias: "Contract Status",
      cell: (row) => {
        return row.contract_status;
      },
      onClick: (data) => {
        InfoForm(data);
      },
    },
  ];
  const data_url = webServiceRoutes.ORDER_LIST;
  const InfoForm = (item) => {
    props.history.push({
      pathname: "/orderInformation",
      state: { ...item, info: true },
    });
  };
  const styles = {
    floatingActionButton: {
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 20,
      left: "auto",
      position: "fixed",
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <PageBase title="Orders" navigation="Orders">
      <div>
          <Link to="/addOrder">
            <FloatingActionButton
              style={styles.floatingActionButton}
              backgroundColor={pink500}
            >
              <ContentAdd />
            </FloatingActionButton>
          </Link>
        </div>
        <Table info={InfoForm} data_url={data_url} columns={columns} />
      </PageBase>
    </div>
  );
}
